import React, {Component} from 'react'
import './style.scss';

class ActivityIndicatorComponent extends Component {

  render() {
    return (
      <div className="activity-indicator-container">
        <div className="activity-container-column">
          <svg width={210} height={210} viewBox="0 0 420 420">
            <g fill="none" fillRule="evenodd">
              <path
                className="activity-indicator-blade"
                fill="#FF9802"
                fillRule="nonzero"
                d="M198 114V45.58c1.857-6.386 6.023-9.58 12.5-9.58s10.643 3.194 12.5 9.58V114h-25z"
              />
              <path
                className="activity-indicator-blade"
                fill="#FFB302"
                fillRule="nonzero"
                d="M270.336 132.59l48.38-48.38c5.83-3.203 11.034-2.515 15.614 2.065s5.267 9.784 2.064 15.613l-48.38 48.38-17.678-17.678z"
              />
              <path
                className="activity-indicator-blade"
                fill="#FFCA28"
                fillRule="nonzero"
                d="M306.93 197.33h68.42c6.386 1.857 9.58 6.023 9.58 12.5s-3.194 10.643-9.58 12.5h-68.42v-25z"
              />
              <path
                className="activity-indicator-blade"
                fill="#FDD835"
                fillRule="nonzero"
                d="M222.816 306.416v68.42c-1.857 6.386-6.023 9.58-12.5 9.58s-10.643-3.194-12.5-9.58v-68.42h25z"
              />
              <path
                className="activity-indicator-blade"
                fill="#FFEB3B"
                fillRule="nonzero"
                d="M290.677 273l48.38 48.38c3.204 5.828 2.516 11.033-2.064 15.613s-9.784 5.268-15.614 2.064L273 290.677 290.678 273z"
              />
              <path
                className="activity-indicator-blade"
                fill="#FFEB3B"
                fillRule="nonzero"
                d="M151.33 286.903l-48.38 48.38c-5.83 3.203-11.034 2.515-15.614-2.065s-5.267-9.784-2.064-15.613l48.38-48.38 17.678 17.678z"
              />
              <path
                className="activity-indicator-blade"
                fill="#FFEE59"
                fillRule="nonzero"
                d="M113.682 222.098h-68.42c-6.386-1.857-9.58-6.023-9.58-12.5s3.194-10.643 9.58-12.5h68.42v25z"
              />
              <path
                className="activity-indicator-blade"
                fill="#FFF176"
                fillRule="nonzero"
                d="M133.487 149.565l-48.38-48.38c-3.204-5.829-2.516-11.033 2.064-15.613s9.784-5.268 15.614-2.064l48.38 48.38-17.678 17.677z"
              />
            </g>
            <g fill="none" fillRule="evenodd">
              <circle cx={210} cy={209} r={60} stroke="#FF9802" strokeWidth={25} />
            </g>
          </svg>
          <div className="activity-indicator-message">
            {
              this.props.message ?
              this.props.message.map((msg, index) => (
                <p key={'activity_message'+index}>{msg}</p>
              )) : "Loading ..."
            }
          </div>
        </div>
      </div>
    )
  }
}

export default ActivityIndicatorComponent
