import React, {Component} from 'react';
import './style.scss';

class ImageOptionComponent extends Component {

  render() {
    return (
      <div className="image-option-container" style={{...this.props}}>
        { this.props.children }
      </div>
    )
  }
}

export default ImageOptionComponent;
