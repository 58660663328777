import React from 'react'

const SvgLogoComponent = props => (
  <svg width={props.width || 210} height={props.height || 30} {...props} viewBox="0 0 457 65">
    <g fillRule="evenodd">
      <path
        d="M76.52 21.66v6.53H60.68v11.07h14v6.53h-14v11.67h15.84V64H52.15V21.66zM80.33 40.28A9 9 0 0 1 84 33c2.46-2 5.727-3 9.8-3s7.34.98 9.8 2.94a10.3 10.3 0 0 1 3.93 8h-8.69c-.24-2.82-2-4.23-5.2-4.23a6.3 6.3 0 0 0-3.75 1 3.12 3.12 0 0 0-1.33 2.66 2.94 2.94 0 0 0 2 2.66 19.72 19.72 0 0 0 4.84 1.52c1.9.36 3.78.81 5.66 1.36a10.6 10.6 0 0 1 4.8 2.9 7.68 7.68 0 0 1 2 5.53 8.77 8.77 0 0 1-3.86 7.29 16 16 0 0 1-9.8 2.84 16 16 0 0 1-9.76-2.81 10.36 10.36 0 0 1-4.18-8.07H89c.32 2.82 2.12 4.23 5.38 4.23a6.26 6.26 0 0 0 3.81-1.06A3.27 3.27 0 0 0 99.62 54a3 3 0 0 0-2-2.72 18.37 18.37 0 0 0-4.84-1.54c-1.89-.37-3.78-.81-5.65-1.33a10.68 10.68 0 0 1-4.81-2.81 7.24 7.24 0 0 1-1.99-5.32zm44.75 16.57h4.06V64h-5.39c-3.42 0-6.07-.79-7.92-2.36-1.85-1.57-2.78-4.17-2.78-7.8v-16.4h-3.57v-6.95h3.57V22.2h8.53v8.29h7.49v7h-7.49v16.34a3 3 0 0 0 .78 2.33c.8.538 1.76.782 2.72.69z"
      />
      <path
        fillRule="nonzero"
        d="M142.2 22a5.06 5.06 0 0 1-1.51 3.69 5.17 5.17 0 0 1-8.83-3.69 5.17 5.17 0 0 1 10.34 0zm-9.44 42V30.49h8.53V64h-8.53z"
      />
      <path
        d="M155.86 30.49v5C158 31.83 161.47 30 166.27 30a13.2 13.2 0 0 1 6.8 1.76 11.8 11.8 0 0 1 4.63 5 13 13 0 0 1 4.8-5 13.21 13.21 0 0 1 6.87-1.76 12.86 12.86 0 0 1 9.63 3.75c2.44 2.5 3.66 6.01 3.66 10.53V64h-8.46V45.48a8.35 8.35 0 0 0-2-6 7.19 7.19 0 0 0-5.44-2.09 7.29 7.29 0 0 0-5.48 2.09 8.27 8.27 0 0 0-2 6V64h-8.48V45.48a8.35 8.35 0 0 0-2-6 8.18 8.18 0 0 0-10.92 0 8.28 8.28 0 0 0-2 6V64h-8.52V30.49h8.5z"
      />
      <path
        fillRule="nonzero"
        d="M210.56 59.61a17.6 17.6 0 0 1-4.5-12.43 17.46 17.46 0 0 1 4.47-12.37A14.87 14.87 0 0 1 221.9 30a11.73 11.73 0 0 1 10.52 5.69v-5.2h8.47V64h-8.47v-5.63c-2.54 4.067-6.067 6.1-10.58 6.1a14.73 14.73 0 0 1-11.28-4.86zm19.2-5.14a9.7 9.7 0 0 0 2.66-7.23 9.7 9.7 0 0 0-2.66-7.24 8.68 8.68 0 0 0-12.42 0 9.65 9.65 0 0 0-2.64 7.2 9.81 9.81 0 0 0 2.67 7.26 8.58 8.58 0 0 0 12.39 0v.01z"
      />
      <path
        d="M259.94 56.85H264V64h-5.38c-3.433 0-6.073-.787-7.92-2.36-1.847-1.573-2.777-4.173-2.79-7.8v-16.4h-3.56v-6.95h3.56V22.2h8.53v8.29h7.5v7h-7.5v16.34a3 3 0 0 0 .79 2.33 4.14 4.14 0 0 0 2.71.69z"
      />
      <path
        fillRule="nonzero"
        d="M281.77 64.47a15.84 15.84 0 0 1-11.91-4.74c-3.073-3.167-4.607-7.35-4.6-12.55.007-5.2 1.55-9.363 4.63-12.49a16.09 16.09 0 0 1 12-4.69 16.63 16.63 0 0 1 12.06 4.57c3.147 3.04 4.72 7.1 4.72 12.18a20.93 20.93 0 0 1-.27 3.25H274a8 8 0 0 0 2.42 5.26 7.53 7.53 0 0 0 5.38 2 7 7 0 0 0 6.53-3.63h9.19a14.7 14.7 0 0 1-5.62 7.8 16.72 16.72 0 0 1-10.13 3.04zm5.59-25.27a8 8 0 0 0-5.47-2 7.55 7.55 0 0 0-5.35 2 8.43 8.43 0 0 0-2.54 5.32h15.84a7.3 7.3 0 0 0-2.48-5.32z"
      />
      <path
        d="M310.07 59.15a4.63 4.63 0 0 1-1.42 3.45 4.75 4.75 0 0 1-3.45 1.4 4.61 4.61 0 0 1-3.41-1.39 5 5 0 0 1 0-6.89 4.62 4.62 0 0 1 3.41-1.4 4.76 4.76 0 0 1 3.45 1.4 4.59 4.59 0 0 1 1.42 3.43zM327.79 28a6.33 6.33 0 0 0-4.14 1.3 4.38 4.38 0 0 0-1.61 3.6 5.16 5.16 0 0 0 1.18 3.59 7.55 7.55 0 0 0 3.12 2 39.6 39.6 0 0 0 4.23 1.24c1.53.36 3.06.81 4.6 1.33A22.11 22.11 0 0 1 339.4 43a8.28 8.28 0 0 1 3.11 3.35 11.19 11.19 0 0 1 1.18 5.35 11.67 11.67 0 0 1-4.14 8.95c-2.76 2.5-6.427 3.75-11 3.75s-8.273-1.077-11.1-3.23a10.8 10.8 0 0 1-4.23-9.1h9.07c.32 3.707 2.437 5.56 6.35 5.56a6.63 6.63 0 0 0 4.59-1.51 5.13 5.13 0 0 0 1.7-4 4.49 4.49 0 0 0-2.24-4 16.61 16.61 0 0 0-5.41-2.12c-2.12-.49-4.25-1.07-6.38-1.76a11.89 11.89 0 0 1-5.45-3.59 10 10 0 0 1-2.23-6.81 11.32 11.32 0 0 1 4.17-9.19 16.28 16.28 0 0 1 10.73-3.45c4.38 0 7.897 1 10.55 3 2.653 2 4.107 5.033 4.36 9.1h-9.31a5.78 5.78 0 0 0-1.82-3.84 5.89 5.89 0 0 0-4.11-1.46z"
      />
      <path
        fillRule="nonzero"
        d="M351.43 59.7c-3.26-3.193-4.89-7.367-4.89-12.52s1.65-9.307 4.95-12.46c7-6.295 17.62-6.295 24.62 0 3.333 3.147 5 7.3 5 12.46a16.58 16.58 0 0 1-5 12.52 17.21 17.21 0 0 1-12.37 4.77 16.9 16.9 0 0 1-12.31-4.77zm12.28-2.42a8.31 8.31 0 0 0 6.14-2.63c1.707-1.76 2.563-4.24 2.57-7.44.007-3.2-.84-5.67-2.54-7.41a8.28 8.28 0 0 0-6.14-2.6 8.1 8.1 0 0 0-6.08 2.6c-1.653 1.733-2.48 4.213-2.48 7.44 0 3.227.827 5.707 2.48 7.44a8.07 8.07 0 0 0 6.05 2.6z"
      />
      <path d="M384.81 64V19.24h8.53V64z" />
      <path
        fillRule="nonzero"
        d="M401.53 59.61A17.6 17.6 0 0 1 397 47.18a17.46 17.46 0 0 1 4.47-12.37 14.9 14.9 0 0 1 11.4-4.81 11.74 11.74 0 0 1 10.53 5.69v-5.2h8.46V64h-8.46v-5.63c-2.54 4.067-6.07 6.1-10.59 6.1a14.76 14.76 0 0 1-11.28-4.86zm19.21-5.14a9.74 9.74 0 0 0 2.66-7.23 9.74 9.74 0 0 0-2.66-7.24 8.69 8.69 0 0 0-12.43 0 9.65 9.65 0 0 0-2.63 7.2 9.8 9.8 0 0 0 2.66 7.26 8.59 8.59 0 0 0 12.4 0v.01z"
      />
      <path
        d="M446.44 30.49v5.92c2.253-4.267 5.517-6.403 9.79-6.41v8.71h-2.11a7.88 7.88 0 0 0-5.75 1.91c-1.29 1.27-1.93 3.45-1.93 6.56V64h-8.53V30.49h8.53z"
      />
      <path
        fill={props.fillColor === '#195054' ? 'none' : '#FACC44'}
        stroke={props.fillColor !== '#195054' ? '#195054' : '#FACC44'}
        d="M.5 19.824C11.055 19.563 19.563 11.054 19.824.5H.5v19.324z"
      />
      <path
        d="M22.4 2l-.69.69v14.95l2.69 2.69h14.94l.7-.69zM41.45 18.22l.59-.58V2.69L39.34 0H24.4l-.58.58zM41.41 39.87l.63-.63V24.3l-2.7-2.7H24.4l-.63.63zM22.36 23.65l-.65.65v14.94l2.69 2.69h14.94l.65-.65zM19.66 39.91l.67-.67V24.3l-2.69-2.7H2.69l-.66.67zM.61 23.68L0 24.3v14.94l2.69 2.69h14.95l.61-.61z"
      />
    </g>
  </svg>
);

export default SvgLogoComponent
