import ArticleImageComponent from '../article/image';
import ArticleHeaderComponent from '../article/header';
import ArticleTitleComponent from '../article/title';
import BlankComponent from '../../components/blank';
import BlockComponent from "../../components/block";
import BlogFooterComponent from '../footer';
import ColumnComponent from "../../components/layout/column";
import LabelComponent from "../../components/label";
import RowComponent from "../../components/layout/row";

export const Stock = {
  ArticleImageComponent,
  ArticleHeaderComponent,
  ArticleTitleComponent,
  BlankComponent,
  BlockComponent,
  BlogFooterComponent,
  ColumnComponent,
  LabelComponent,
  RowComponent
};
