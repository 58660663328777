import React from 'react';

const MapAnnotationComponent = props => (
  <div style={{...props}}>
    <svg width={215} height={84}>
      <g fill="none" fillRule="evenodd">
        <path
          fill="#1B1B1B"
          stroke="#FFF"
          strokeOpacity={0.5}
          d="M43.707 41.934l5.934 5.934v26.137a5.497 5.497 0 0 0 5.5 5.495h148.86a5.5 5.5 0 0 0 5.501-5.495V9.995a5.497 5.497 0 0 0-5.5-5.495H55.142a5.5 5.5 0 0 0-5.501 5.495V36l-5.934 5.934z"
          opacity={0.5}
        />
        <text
          fill="#FFF"
          fontFamily="Roboto-Regular, Roboto"
          fontSize={14}
          transform="translate(43 4)"
        >
          <tspan x={20} y={27}>
            {props.title}
          </tspan>
          <tspan x={20} y={49}>
            {props.subtitle}
          </tspan>
        </text>
        <g transform="translate(4 27)">
          <circle
            cx={15}
            cy={15}
            r={15}
            stroke="#F7CA43"
            strokeWidth={2}
            opacity={0.3}
          />
          <circle cx={15} cy={15} r={9} fill="#F7CA43" />
        </g>
      </g>
    </svg>
  </div>
);

export default MapAnnotationComponent
