import React, { Component } from 'react';
import {
  APPEND_SUBMIT_DATA_ACTION,
  UPDATE_JORNAYA_TOKEN_ACTION,
  UPDATE_SUBMIT_DATA_VALUE_BY_ID_ACTION,
  UPDATE_TRUSTED_FORM_CERTIFICATE_ACTION
} from "../../redux/actions/index";
import connect from "react-redux/es/connect/connect";

const mapDispatchToProps = dispatch => {
  return {
    appendSubmitData: data => dispatch(APPEND_SUBMIT_DATA_ACTION(data)),
    updateSubmitData: obj => dispatch(UPDATE_SUBMIT_DATA_VALUE_BY_ID_ACTION(obj)),
    updateJornayaToken: token => dispatch(UPDATE_JORNAYA_TOKEN_ACTION(token)),
    updateTFormCertificate: data => dispatch(UPDATE_TRUSTED_FORM_CERTIFICATE_ACTION(data))
  };
};

class TokenProviderComponent extends Component {

  componentWillMount() {
    if (this.props.provider === "Jornaya") {
      // preparing Jornaya Script
      let jScript = document.createElement('script');
      jScript.id = 'LeadiDscript_campaign';
      jScript.type = 'text/javascript';
      jScript.async = true;
      jScript.src = 'http' + ('https:' === document.location.protocol ? 's':'')+'://create.lidstatic.com/campaign/0d78f0ea-f1ce-ea27-19f2-0734aa33f962.js?snippet_version=2&callback=jornayaCallback';
      let jDoc = document.getElementsByTagName('script')[0];
      jDoc.parentNode.insertBefore(jScript, jDoc);
    }

    if (this.props.provider === "TrustedForm") {
      // preparing TrustedFrom Script
      let field = 'xxTrustedFormCertUrl';
      let provideReferrer = false;
      let invertFieldSensitivity = false;
      let tfScript = document.createElement('script');
      tfScript.type = 'text/javascript';
      tfScript.async = true;
      tfScript.src = 'http' + ('https:' === document.location.protocol ? 's' : '') +
        '://api.trustedform.com/trustedform.js?provide_referrer=' + escape(provideReferrer) + '&field=' + escape(field) + '&l='+ new Date().getTime()+Math.random() + '&invert_field_sensitivity=' + invertFieldSensitivity;
      let tfDoc = document.getElementsByTagName('script')[0];
      tfDoc.parentNode.insertBefore(tfScript, tfDoc);
    }

    // prepares data obj to be submitted
    const obj = {
      fieldId: this.props.fieldID,
      value: ''
    };
    this.props.appendSubmitData(obj);
  }

  componentDidMount() {
    const localThis = this;

    if (this.props.provider === "Jornaya") {
      // update token after callback done it's job.
      setTimeout(function () {
        localThis.props.updateJornayaToken(window.jornayaToken);
        const obj = {
          fieldId: localThis.props.fieldID,
          value: window.jornayaToken
        };
        localThis.props.updateSubmitData(obj);
      }, 2000);
    }
    if (this.props.provider === "TrustedForm") {
      // update token after callback done it's job.
      setTimeout(function () {
        localThis.props.updateTFormCertificate(window.trustedFormCertUrl);
        const obj = {
          fieldId: localThis.props.fieldID,
          value: window.trustedFormCertUrl
        };
        localThis.props.updateSubmitData(obj);
      }, 2000);
    }
  }

  render() {
    return (
      <React.Fragment>
        <div style={{display: "none"}}>{this.props.provider}</div>
        <input id="leadid_token" name="universal_leadid" type="hidden" value=""/>;
      </React.Fragment>
    )
  }
}

export default connect(null, mapDispatchToProps)(TokenProviderComponent);
