import React, {Component} from 'react';
import './style.scss';

class ImageComponent extends Component {
  render() {
    return (
      <div className="image-component-container">
        <img
          alt={this.props.alt}
          className="image-component-asset"
          src={this.props.url}
        />
      </div>
    );
  }
}

export default ImageComponent;
