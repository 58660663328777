import React, { Component } from 'react';
import Page from '../../containers/page/index';
import {
  FETCH_PAGE_ACTION,
  FETCH_PREVIOUS_PAGE_ACTION,
  FETCH_SESSION_ACTION,
  UPDATE_APP_CAMPAIGN_ACTION
} from '../../redux/actions';
import {withRouter} from "react-router";
import connect from "react-redux/es/connect/connect";
import 'rc-dialog/assets/index.css';
import './style.scss';
import queryString from 'query-string';

const mapDispatchToProps = dispatch => {
  return {
    fetchPage: (id) => dispatch(FETCH_PAGE_ACTION(id)),
    fetchPrevPage: (id) => dispatch(FETCH_PREVIOUS_PAGE_ACTION(id)),
    fetchSession: (pageGuid) => dispatch(FETCH_SESSION_ACTION(pageGuid)),
    updateCampaign: (campaign) => dispatch(UPDATE_APP_CAMPAIGN_ACTION(campaign))
  };
};

const mapStateToProps = state => {
  return {
    isSessionFetched: state.api.isSessionFetched,
    isSessionFetching: state.api.isSessionFetching,
    pageId: state.navigation.pageId,
    previousPageId: state.navigation.previousPageId
  };
};

class EngineContainerComponent extends Component {

  componentWillMount() {
    // get queryStrings
    const values = queryString.parse(this.props.location.search);
    if (values.campaign) {
      if (values.campaign.constructor === Array) {
        this.props.updateCampaign(values.campaign[values.campaign.length-1]);
      } else {
        this.props.updateCampaign(values.campaign);
      }
    }
  }

  componentDidMount() {
    let localThis = this;
    const { match: { params } } = this.props;

    if (!this.props.isSessionFetched && !this.props.isSessionFetching) {
      if (params.guid) {
        setTimeout(function() { localThis.props.fetchSession(params.guid); }, 500);
      } else {
        setTimeout(function() { localThis.props.fetchSession(); }, 500);
      }
    }

    window.onpopstate = function (event) {
      localThis.props.fetchPrevPage(localThis.props.pageId);
    };
    if (window.performance) {
      if (window.performance.navigation.type === 1 && this.props.isSessionFetched && params.guid) {
        setTimeout(function() { localThis.props.fetchSession(params.guid); }, 500);
      } else {
        console.log('This page is not reloaded');
      }
    }
  }

  render() {
    return (
      <Page />
    );
  }

}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(EngineContainerComponent));

