import React, { Component } from 'react';
import ColumnComponent from "../layout/column";
import DialogWrap from 'rc-dialog';
import {Document, Page} from "react-pdf";
import { pdfjs } from 'react-pdf';
import './style.scss';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

class AssetDialogComponent extends Component {
  state = {
    visible: false,
    destroyOnClose: false,
    height: '100%',
    width: 'auto',
    maskClosable: false,
    mousePosition: {},
    useIcon: false,
    numPages: null
  };

  onDocumentLoadSuccess = ({ numPages }) => {
    this.setState({ numPages });
  };

  onClick = (event) => { this.setState({ visible: true }); };
  onClose = (event) => { this.setState({ visible: false }); };

  render() {
    const { numPages } = this.state;
    const pdfUrl = this.props.url || "../assets/proposal/report1.pdf";

    return (
      <div className="solar-report-container" style={{...this.props}}>
        <button onClick={this.onClick} type="button">
          See Sample Report
        </button>
        <DialogWrap
          visible={this.state.visible}
          wrapClassName='solar-report-center'
          animation='zoom'
          center={true}
          closable={true}
          keyboard={false}
          maskAnimation='fade'
          maskClosable={true}
          onClose={this.onClose}
          style={this.state.width}
          destroyOnClose={true}
        >
          <ColumnComponent alignContent='center' alignItems='center' justifyContent='center'>
            <Document file={pdfUrl} onLoadSuccess={this.onDocumentLoadSuccess}>
              {Array.from(new Array(numPages),
                (el, index) => (
                  <Page key={`page_${index + 1}`} pageNumber={index + 1} />
                ),
              )}
            </Document>
          </ColumnComponent>
        </DialogWrap>
      </div>
    );
  }

}

export default AssetDialogComponent;
