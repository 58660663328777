import React, {Component} from 'react';
import { connect } from 'react-redux';
import {
  UPDATE_LOCATION_COMPONENT_ACTIVE_FLAG_ACTION,
  UPDATE_LOCATION_COMPONENT_FIELD_ID_ACTION
} from '../../../redux/actions';

const mapDispatchToProps = dispatch => {
  return {
    // this makes the state aware that we have a LocationComponent active so the submit buttons know
    updateLocationStoreFlag: flag => dispatch(UPDATE_LOCATION_COMPONENT_ACTIVE_FLAG_ACTION(flag)),
    updateLocationStoreFieldId: id => dispatch(UPDATE_LOCATION_COMPONENT_FIELD_ID_ACTION(id))
  };
};

const mapStateToProps = state => {
  return {
    mapSize: state.map.size,
  };
};

class LocationComponent extends Component {

  componentDidMount() {
    this.props.updateLocationStoreFlag(true);
    this.props.updateLocationStoreFieldId(this.props.fieldID);
  }

  componentWillUnmount() {
    this.props.updateLocationStoreFlag(false);
    this.props.updateLocationStoreFieldId('');
  }

  render() {
    const { height, width } = this.props.mapSize;
    return (
      <div style={{position: 'relative', zIndex: 3, top: -(height/2 + 75.5), left: (width/2 - 101.5)}}>
        <div style={{position: 'absolute'}}>
          <svg width={203} height={151} viewBox="0 0 203 151" >
            <g fill="none" fillRule="evenodd">
              <rect width={203} height={70} y={81} fill="#F7CA43" rx={6} />
              <text fill="#274E53" fontFamily="Roboto-Regular, Roboto" fontSize={14}>
                <tspan x={17.397} y={104}>
                  {'Drag the map to make sure'}
                </tspan>
                <tspan x={32.515} y={126}>
                  {'your home is centered'}
                </tspan>
              </text>
              <g fillRule="nonzero" stroke="#F7CA43">
                <path strokeLinecap="round" d="M73 41.5h15" />
                <g transform="translate(82 13)">
                  <path strokeLinecap="round" d="M20.5 41v15M20.5 0v15" />
                  <circle cx={20} cy={28} r={20} />
                </g>
                <path strokeLinecap="round" d="M116 41.5h15" />
              </g>
            </g>
          </svg>
        </div>
      </div>
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(LocationComponent);
