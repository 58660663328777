import React, {Component} from 'react';
import { connect } from 'react-redux';
import {
  UPDATE_LOCATION_COMPONENT_ACTIVE_FLAG_ACTION,
  UPDATE_LOCATION_COMPONENT_FIELD_ID_ACTION
} from '../../../redux/actions';

const mapDispatchToProps = dispatch => {
  return {
    // this makes the state aware that we have a LocationComponent active so the submit buttons know
    updateLocationStoreFlag: flag => dispatch(UPDATE_LOCATION_COMPONENT_ACTIVE_FLAG_ACTION(flag)),
    updateLocationStoreFieldId: id => dispatch(UPDATE_LOCATION_COMPONENT_FIELD_ID_ACTION(id))
  };
};

const mapStateToProps = state => {
  return {
    mapSize: state.map.size,
  };
};

class LocationWithoutTextComponent extends Component {

  componentDidMount() {
    this.props.updateLocationStoreFlag(true);
    this.props.updateLocationStoreFieldId(this.props.fieldID);
  }

  componentWillUnmount() {
    this.props.updateLocationStoreFlag(false);
    this.props.updateLocationStoreFieldId('');
  }

  render() {
    const { height, width } = this.props.mapSize;
    return (
      <div style={{position: 'relative', zIndex: 3, top: -(height/2 + 28), left: (width/2 - 29)}}>
        <div style={{position: 'absolute'}}>
          <svg width={58} height={56} viewBox="0 0 58 56" >
            <g fill="none" fillRule="evenodd" stroke="#F7CA43" strokeWidth={2}>
              <path strokeLinecap="round" d="M0 28.5h15M29.5 41v15M29.5 0v15" />
              <circle cx={29} cy={28} r={20} />
              <path strokeLinecap="round" d="M43 28.5h15" />
            </g>
          </svg>
        </div>
      </div>
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(LocationWithoutTextComponent);
