import {
  APPEND_VALIDATION_ITEM,
  UPDATE_VALIDATION_HAS_ERRORS_FLAG,
  UPDATE_VALIDATION_ITEMS,
  UPDATE_VALIDATION_PAGE_ID,
  RESET_VALIDATION_STATE,
  UPDATE_VALIDATION_STATE
} from '../constants/action_types';

export const APPEND_VALIDATION_ITEM_ACTION = bool => ({
  type: APPEND_VALIDATION_ITEM,
  payload: bool
});

export const UPDATE_VALIDATION_HAS_ERRORS_FLAG_ACTION = bool => ({
  type: UPDATE_VALIDATION_HAS_ERRORS_FLAG,
  payload: bool
});

export const UPDATE_VALIDATION_ITEMS_ACTION = items => ({
  type: UPDATE_VALIDATION_ITEMS,
  payload: items
});

export const UPDATE_VALIDATION_PAGE_ID_ACTION = pageId => ({
  type: UPDATE_VALIDATION_PAGE_ID,
  payload: pageId
});

export const RESET_VALIDATION_STATE_ACTION = () => ({
  type: RESET_VALIDATION_STATE
});

export const UPDATE_VALIDATION_STATE_ACTION = obj => ({
  type: UPDATE_VALIDATION_STATE,
  payload: obj
});
