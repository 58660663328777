import React, {Component} from 'react';
import { Stock } from '../../stock';
import uuid4 from 'uuid4';

class DynamicBlock extends Component {

  get root() {
    return this.props.root;
  }

  get rSection() {
    return this.createElement(this.root);
  }

  createElement = (def) => {
    def.props = Object.assign({}, def.props, {
      key: uuid4()
    });

    if (def.props.id) {
      return React.createElement(
        Stock[def.component],
        def.props,
        ((def || {}).children || [])
          .map(c => {
              let propy = Object.assign({}, c);
              propy.props.parentFieldId = def.props.id;
              return this.createElement(propy)
            }
          )
      );
    } else {
      return React.createElement(
        Stock[def.component],
        def.props,
        ((def || {}).children || [])
          .map(c => this.createElement(c))
      );
    }

  };

  render() {
    return (
      <React.Fragment>
        {this.rSection}
      </React.Fragment>
    );
  }
}

export default DynamicBlock;
