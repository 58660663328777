import React from 'react';
import './style.scss';

const InfoMarkerMapComponent = props => (
  <svg width={205} height={232}>
    <g fill="none" fillRule="evenodd">
      <g transform="translate(72 2)">
        <circle
          cx={30}
          cy={30}
          r={30}
          stroke="#F7CA43"
          strokeWidth={4}
          opacity={0.3}
        />
        <circle cx={30} cy={30} r={18} fill="#F7CA43" />
      </g>
      <rect
        width={202.313}
        height={137.648}
        x={1}
        y={93}
        fill="#F7CA43"
        rx={6}
      />
      <text
        fill="#274E53"
        fontFamily="Poppins-SemiBold, Poppins"
        fontSize={48}
        fontWeight={500}
        letterSpacing={-1.846}
      >
        <tspan x={55} y={146}>
          {props.title}
        </tspan>
      </text>
      <text fill="#274E53" fontFamily="Roboto-Regular, Roboto" fontSize={14}>
        <tspan x={21.47} y={179}>
          {props.text1}
        </tspan>
        <tspan x={53.223} y={201}>
          {props.text2}
        </tspan>
      </text>
    </g>
  </svg>
)

export default InfoMarkerMapComponent
