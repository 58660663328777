import React, {Component} from 'react';
import './style.scss';

class BlankComponent extends Component {

  goBack = (ev) =>{
    ev.preventDefault();
    window.location.reload();
  };

  render() {
    return (
      <div className="nocontent-container" style={{...this.props}}>
        <div className="nocontent-message">
          <h1>No Content Found!</h1>
          <button onClick={this.goBack} type="button">
            Refresh
          </button>
        </div>
      </div>
    );
  }
}

export default BlankComponent;
