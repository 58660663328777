import ReducerUtilFunctions from "./util";

import {
  UPDATE_AZIMUTH_COMPONENT_ACTIVE_FLAG,
  UPDATE_AZIMUTH_COMPONENT_FIELD_ID,
  UPDATE_AZIMUTH_VALUE
} from '../constants/action_types';

const initialState = {
  isActive: false,
  fieldId: '',
  value: null
};

export default function azimuthReducer(state = initialState, action) {
  switch(action.type) {
    case UPDATE_AZIMUTH_COMPONENT_ACTIVE_FLAG: {
      return ReducerUtilFunctions.updateObject(state, { isActive: action.payload });
    }
    case UPDATE_AZIMUTH_COMPONENT_FIELD_ID: {
      return ReducerUtilFunctions.updateObject(state, { fieldId: action.payload });
    }
    case UPDATE_AZIMUTH_VALUE: {
      return ReducerUtilFunctions.updateObject(state, { value: action.payload });
    }
    default:
      // ALWAYS have a default case in a reducer
      return state;
  }
}
