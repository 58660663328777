const WellKnownFields = {
  SiteName: "SiteName",
  SiteUrl: "SiteUrl",
  SiteVersion: "SiteVersion",
  SiteClass: "SiteClass",
  UserAgent: "UserAgent",
  Campaign: "Campaign",
  CompleteAddress: "CompleteAddress",

  StandardizedAddress: "StandardizedAddress",
  StandardizedCity: "StandardizedCity",
  StandardizedState: "StandardizedState",
  StandardizedZipCode: "StandardizedZipCode",
  StandardizedPlusFlour: "StandardizedPlusFlour",

  AddressID: "AddressID",
  Address: "Address",
  City: "City",
  State: "State",
  ZipCode: "ZipCode",
  PlusFlour: "PlusFlour",
  ZipCode_Latitude: "ZipCode_Lat",
  ZipCode_Longitude: "ZipCode_Lon",
  TimeZone: "TimeZone",

  MobilePhoneNumber: "MobilePhone",
  MobilePhoneValidationCode: "MobilePhoneValidationCode",

  EmailAddress: "EmailAddress",

  HomeOwnerConfirmed: "HomeOwnerConfirmed",
  PhoneNumberConfirmed: "PhoneNumberConfirmed",
  EmailConfirmed: "EmailConfirmed",

  County: "County",
  AddressType: "AddressType",
  Urbanization: "Urbanization",
  Msa: "Msa",

  IP_Address: "IP_Address",
  IP_Region: "IP_Region",
  IP_City: "IP_City",
  IP_ZipCode: "IP_ZipCode",
  IP_Provider: "IP_Provider",
  IP_Country: "IP_Country",
  IP_Latitude: "IP_Lat",
  IP_Longitude: "IP_Lon",

  FirstName: "FirstName",
  LastName: "LastName",
  FullName: "FullName",
  Latitude: "Latitude",
  Longitude: "Longitude",

  MonthlyBill: "MonthlyBill",
  Coordinates: "Coordinates",
  Azimuth: "Azimuth",
  RoofPitch: "RoofPitch",
  RoofShade: "RoofShade",

  SolarSavingsTotal: "SolarSavingsTotal",
  SolarSavingsTotalFormatted: "SolarSavingsTotalFormatted",
  ScenarioLength: "ScenarioLength",

  SolarUtilityEscalator: "SolarUtilityEscalator",
  SolarSavingsMonthly: "SolarSavingsMonthly",
  SolarSavingsMonthlyFormatted: "SolarSavingsMonthlyFormatted",

  SolarSystemSize: "SolarSystemSize",
  SolarSystemSizeFormatted: "SolarSystemSizeFormatted",

  FinancingLength: "FinancingLength",
  FinancingApr: "FinancingApr",

  SolarPanelCount: "SolarPanelCount",
  SolarPricePerWatt: "SolarPricePerWatt",
  SolarPanelSize: "SolarPanelSize",
  BreakEvenYears: "BreakEvenYears",


  PowerCompanyName: "PowerCompanyName",
  PowerCompanyID: "PowerCompanyID",
  TariffID: "TariffID",
  TariffName: "TariffName",

  FinancingType: "FinancingType"
};

const googleConfig = {
  apiKey: 'AIzaSyBRPhAKo2HbV6NNb8Euyvip6NNw_Wp7k8k'
};

export {
  googleConfig,
  WellKnownFields
};
