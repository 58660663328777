import React, {Component} from 'react';
import './style.scss';

class ChipComponent extends Component {

  render() {
    return (
      <div className="chip-component-container">
        { this.props.children }
      </div>
    )
  }
}

export default ChipComponent;
