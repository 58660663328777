import React from 'react';
import "./style.scss";

const MapAnnotationComponent1 = (props) => (
  <div className="map-annotation-container">
    <div className="map-annotation-dot" />
    <span className="map-annotation-text">
      {props.title}
    </span>
  </div>
);

export default MapAnnotationComponent1;
