import React, {Component} from 'react';
import EngineButtonComponent from "../engine/index";
import ColumnComponent from "../../components/layout/column";
import LabelComponent from "../../components/label";
import ArticleHeroComponent from "../article/hero/index";
import BlogFooterComponent from "../footer";
import PageTitleLogoComponent from "../../components/title_logo";

class MainBlogComponent extends Component {
  render() {
    return (
      <ColumnComponent
        backgroundColor="#F7CA43" justifyContent="flex-start"
      >
        <PageTitleLogoComponent width={210} height={30} />
        <ColumnComponent
          alignContent="center"
          alignItems="center"
          backgroundImage="linear-gradient(#F7CA43, #F9F3DF)"
          justifyContent="center"
          flex="1"
        >
          <LabelComponent
            marginTop="50px"
            text="Is Solar Worth it For my Home?"
            type="h1"
          />
          <LabelComponent
            marginTop="45px"
            text="If You Consider Going Solar And Wonder How Many Solar Panels do I Need For my House,"
            type="h4"
          />
          <LabelComponent
            text="Check Out The Solar Panels Calculator And Find Out How Much it Costs to Enjoy The Benefits of Renewable Energy!"
            type="h4"/>
          <EngineButtonComponent marginTop="38px"/>
          <LabelComponent marginTop="50px" text="Solar Industry News" type="h1"/>
          <ArticleHeroComponent
            articleLink="solar-energy-advantages-and-disadvantages"
            coverUrl="https://res.cloudinary.com/minmax/image/upload/estimate.solar/2019-01-21/cover-xlarge.jpg"
            date="January 21, 2019"
            title="Solar Energy Pros And Cons"
            snippet="Wondering about the solar panels efficiency and whether renewable energy is right for you? Find out what the solar energy advantages and disadvantages are before installing solar panels on your rooftop. Solar Panels makes sense for most people but is not always a guarantee for savings."
          />
          <ArticleHeroComponent
            articleLink="2018-annual-solar-review"
            coverUrl="https://res.cloudinary.com/minmax/image/upload/estimate.solar/2018-12-10/cover-xlarge.jpg"
            date="December 10, 2018"
            title="2018 Annual Solar Review"
            snippet="2017 was an year of extraordinary progress but also a few obstacles for the solar industry. Lately, things have slowly started moving in the right direction for those looking to purchase solar panels for their homes however. For example, the cost of solar panels has decreased and the availability of quality solar companies has increased."
          />
          <ArticleHeroComponent
            articleLink="2018-state-solar-policies-updates-you-should-know"
            coverUrl="https://res.cloudinary.com/minmax/image/upload/estimate.solar/2018-11-23/cover-xlarge.jpg"
            date="November 23, 2018"
            title="2018 State Solar Policies Updates You Should Know"
            snippet="California - One of the updates with the biggest impact comes out from California. Starting 2020 all new homes will have rooftop solar panels, mandated the California Energy Commission on May 9."
          />
          <ArticleHeroComponent
            articleLink="installation-cost-of-solar-panels-in-2018"
            coverUrl="https://res.cloudinary.com/minmax/image/upload/estimate.solar/2018-10-17/cover-xlarge.jpg"
            date="October 17, 2018"
            title="Installation Cost of Solar Panels in 2018"
            snippet="Every house is different and every rooftop has different exposure to the sun. Once you start to explore your options for a potential solar panels system you realize that the financial terms, cash flow calculation and the return-on-investment are more relevant factors than the cost itself."
          />
          <ArticleHeroComponent
            articleLink="how-to-find-the-best-solar-providers-near-me"
            coverUrl="https://res.cloudinary.com/minmax/image/upload/estimate.solar/2018-09-10/cover-xlarge.jpg"
            backgroundColor="#274E53"
            date="September 10, 2018"
            title="How to Find The Best Solar Providers Near Me"
            snippet="If you wish to have a satisfying overall solar energy experience, you should consider finding high-quality providers near you. Here is why choosing a local solar installer may generate more benefits for you than going for the help of a national company."
          />
        </ColumnComponent>
        <BlogFooterComponent/>
      </ColumnComponent>
    );
  }
}

export default MainBlogComponent;
