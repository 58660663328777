import React from 'react'

const ProposalPanelsComponent = props => (
  <svg viewBox="0 0 64 61" height={props.height || 61}>
    <defs>
      <filter id="prefix__a" width="142.1%" height="146.3%" x="-21.1%" y="-24.1%" filterUnits="objectBoundingBox">
        <feOffset dy={2} in="SourceAlpha" result="shadowOffsetOuter1" />
        <feGaussianBlur in="shadowOffsetOuter1" result="shadowBlurOuter1" stdDeviation={2} />
        <feColorMatrix in="shadowBlurOuter1" result="shadowMatrixOuter1" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.5 0" />
        <feMerge>
          <feMergeNode in="shadowMatrixOuter1" />
          <feMergeNode in="SourceGraphic" />
        </feMerge>
      </filter>
      <linearGradient id="prefix__b" x1="64.194%" x2="32.231%" y1="0%" y2="100%">
        <stop offset="0%" stopColor="#85A1A4" />
        <stop offset="100%" stopColor="#274E53" />
      </linearGradient>
      <linearGradient id="prefix__c" x1="64.194%" x2="33.293%" y1="0%" y2="96.676%">
        <stop offset="0%" stopColor="#A2C2C6" />
        <stop offset="100%" stopColor="#587E83" />
      </linearGradient>
      <linearGradient id="prefix__d" x1="64.194%" x2="32.231%" y1="0%" y2="100%">
        <stop offset="0%" stopColor="#A2C2C6" />
        <stop offset="96.676%" stopColor="#587E83" />
      </linearGradient>
    </defs>
    <g fill="none" fillRule="evenodd" filter="url(#prefix__a)" transform="translate(3 2)">
      <path
        fill="#000"
        d="M31.564.163h17.848a3.2 3.2 0 0 1 3.138 2.576l3.978 20a3.2 3.2 0 0 1-3.139 3.824H27.587a3.2 3.2 0 0 1-3.139-3.824l3.978-20A3.2 3.2 0 0 1 31.564.163z"
      />
      <path
        fill="url(#prefix__b)"
        d="M7.876 0h17.848a3.2 3.2 0 0 1 3.138 2.576l3.978 20A3.2 3.2 0 0 1 29.7 26.4H3.899A3.2 3.2 0 0 1 .76 22.576l3.978-20A3.2 3.2 0 0 1 7.876 0z"
        transform="translate(23.688 .163)"
      />
      <path stroke="#638387" strokeWidth={0.8} d="M25.263 18.163h30.45" />
      <path stroke="#7D999D" strokeWidth={0.8} d="M27.539 8.128h26.074" />
      <g>
        <path stroke="url(#prefix__c)" d="M4.2 0L.525 26.4" transform="translate(32.613 .163)" />
        <path stroke="url(#prefix__d)" d="M15.234 0L11.56 26.4" transform="matrix(-1 0 0 1 59.406 .163)" />
      </g>
      <g>
        <path
          fill="#000"
          d="M20.564 13.163h17.848a3.2 3.2 0 0 1 3.138 2.576l3.978 20a3.2 3.2 0 0 1-3.139 3.824H16.587a3.2 3.2 0 0 1-3.139-3.824l3.978-20a3.2 3.2 0 0 1 3.138-2.576z"
        />
        <path
          fill="url(#prefix__b)"
          d="M7.876 0h17.848a3.2 3.2 0 0 1 3.138 2.576l3.978 20A3.2 3.2 0 0 1 29.7 26.4H3.899A3.2 3.2 0 0 1 .76 22.576l3.978-20A3.2 3.2 0 0 1 7.876 0z"
          transform="translate(12.688 13.163)"
        />
        <path stroke="#638387" strokeWidth={0.8} d="M14.263 31.163h30.45" />
        <path stroke="#7D999D" strokeWidth={0.8} d="M16.539 21.128h26.074" />
        <g>
          <path stroke="url(#prefix__c)" d="M4.2 0L.525 26.4" transform="translate(21.613 13.163)"/>
          <path stroke="url(#prefix__d)" d="M15.234 0L11.56 26.4" transform="matrix(-1 0 0 1 48.406 13.163)"/>
        </g>
      </g>
      <g>
        <path
          fill="#000"
          d="M8.564 26.163h17.848a3.2 3.2 0 0 1 3.138 2.576l3.978 20a3.2 3.2 0 0 1-3.139 3.824H4.587a3.2 3.2 0 0 1-3.139-3.824l3.978-20a3.2 3.2 0 0 1 3.138-2.576z"
        />
        <path
          fill="url(#prefix__b)"
          d="M7.876 0h17.848a3.2 3.2 0 0 1 3.138 2.576l3.978 20A3.2 3.2 0 0 1 29.7 26.4H3.899A3.2 3.2 0 0 1 .76 22.576l3.978-20A3.2 3.2 0 0 1 7.876 0z"
          transform="translate(.688 26.163)"
        />
        <path stroke="#638387" strokeWidth={0.8} d="M2.263 44.163h30.45" />
        <path stroke="#7D999D" strokeWidth={0.8} d="M4.539 34.128h26.074" />
        <g>
          <path stroke="url(#prefix__c)" d="M4.2 0L.525 26.4" transform="translate(9.613 26.163)"/>
          <path stroke="url(#prefix__d)" d="M15.234 0L11.56 26.4" transform="matrix(-1 0 0 1 36.406 26.163)"/>
        </g>
      </g>
    </g>
  </svg>
);

export default ProposalPanelsComponent;
