import React, {Component} from 'react';

class MonthSavingsGraphicComponent extends Component {

  static isBBiggerThanA(before, after) {
    return before > after ? 1 : (after > before ? -1 : 0);
  }

  static returnFx(value, comparator) {
    const cValue = MonthSavingsGraphicComponent.isBBiggerThanA(value, comparator);
    return (value <= 0) ? 525 : (value >= 174 ? 351 : (cValue >=0 ? 351 : 525 - Math.floor(value*174/comparator)) );
  }

  static returnPx(value, comparator) {
    return (value <= 0) ? 525 : (value >= 174 ? 351 + Math.floor(value*174/comparator) : 525 - Math.floor(value*174/comparator));
  }

  static calculateBeforeBarValue(before, after) {
    const cValue = MonthSavingsGraphicComponent.isBBiggerThanA(before, after);
    return (cValue >= 0) ? MonthSavingsGraphicComponent.returnFx(before, after) : MonthSavingsGraphicComponent.returnPx(before, after);
  }

  static calculateAfterBarValue(before, after) {
    const cValue = MonthSavingsGraphicComponent.isBBiggerThanA(before, after);
    return (cValue >= 0) ? MonthSavingsGraphicComponent.returnPx(after, before): MonthSavingsGraphicComponent.returnFx(after, before);
  }

  static discount(before, after) {
    const difference = Math.abs(before - after);
    return Math.floor(difference > 0 ? (difference / Math.max(before, after) * 100) : 0);
  };

  static moreOrLessText(before, after) {
    return after <= before ? 'Less' : 'More';
  };

  static renderDefinitions() {
    return (
      <React.Fragment>
        <linearGradient id="a" x1="100%" y1="50%" x2="0%" y2="50%">
          <stop offset="0%" stopColor="#F7CA43" />
          <stop offset="100%" stopColor="#FFF" />
        </linearGradient>
        <linearGradient id="b" x1="1.025%" y1="50%" x2="100%" y2="50%">
          <stop offset="0%" stopColor="#F7CA43" />
          <stop offset="100%" stopColor="#B69738" />
        </linearGradient>
        <circle id="d" cx={102} cy={102.101} r={101.5} />
        <filter
          id="c"
          width="188.7%"
          height="188.7%"
          x="-44.3%"
          y="-44.3%"
          filterUnits="objectBoundingBox"
        >
          <feMorphology
            in="SourceAlpha"
            operator="dilate"
            radius={10}
            result="shadowSpreadOuter1"
          />
          <feOffset in="shadowSpreadOuter1" result="shadowOffsetOuter1" />
          <feGaussianBlur
            in="shadowOffsetOuter1"
            result="shadowBlurOuter1"
            stdDeviation={20}
          />
          <feColorMatrix
            in="shadowBlurOuter1"
            values="0 0 0 0 1 0 0 0 0 0.931622934 0 0 0 0 0.328993056 0 0 0 1 0"
          />
        </filter>
      </React.Fragment>
    )
  };

  render() {
    const { beforeCost, afterCost } = this.props;
    const calculatedA = MonthSavingsGraphicComponent.calculateAfterBarValue(beforeCost, afterCost);
    const calculatedB = MonthSavingsGraphicComponent.calculateBeforeBarValue(beforeCost, afterCost);
    const afterBlockPath = "M280 " + calculatedA + ".01c0-4.424 3.59-8.01 8-8.01a8 8 0 0 1 8 8.01V550h-16V404.01z";
    const beforeBlockPath = "M232 " + calculatedB + ".997c0-4.416 3.59-7.997 8-7.997 4.418 0 8 3.58 8 7.997V550h-16v-87.003z";
    const afterLinePath = "M288 " + calculatedA + ".118v-1h213v1z";
    const beforeLinePath = "M.726 " + calculatedB + ".01v-1H240.72v1z";
    const afterTextYPoint = calculatedA - 8 + 0.618;
    const beforeTextYPoint = calculatedB - 8 + 0.511;
    const sizex = this.props.sizex || 500;
    const sizey = this.props.sizey || 550;
    return (
      <svg
        xmlnsXlink="http://www.w3.org/1999/xlink"
        width={sizex}
        height={sizey}
      >
        <defs>
          {MonthSavingsGraphicComponent.renderDefinitions()}
        </defs>
        <g fill="none" fillRule="evenodd">
          <path
            fill="url(#a)"
            fillRule="nonzero"
            d={afterLinePath}
          />
          <path
            fill="url(#b)"
            fillRule="nonzero"
            d={beforeLinePath}
          />
          <path
            fill="#FFF"
            d={afterBlockPath}
          />
          <path
            fill="#B69738"
            d={beforeBlockPath}
          />
          <text
            fill="#274E53"
            fontFamily="Roboto-Regular, Roboto"
            fontSize={18}
            opacity={0.8}
          >
            <tspan x={27.623} y={beforeTextYPoint}>
              {`$`}{beforeCost}{`/Month Before Solar`}
            </tspan>
          </text>
          <text
            fill="#274E53"
            fontFamily="Roboto-Regular, Roboto"
            fontSize={18}
            opacity={0.8}
          >
            <tspan x={298} y={afterTextYPoint}>
              {`$`}{afterCost}{`/Month After Solar`}
            </tspan>
          </text>
          <g>
            <g fillRule="nonzero" transform="translate(159 48)">
              <use fill="#000" filter="url(#c)" xlinkHref="#d"/>
              <use fill="#F9F3DF" xlinkHref="#d"/>
            </g>
            <text
              fill="#274E53"
              opacity={0.8}
              fontFamily="Roboto-Regular, Roboto"
              fontSize={18}
              transform="translate(159 48)"
            >
              <tspan x={53.465} y={142.094}>
                {`Every Month`}
              </tspan>
            </text>
            <text
              fill="#274E53"
              opacity={0.8}
              fontFamily="Roboto-Regular, Roboto"
              fontSize={18}
              transform="translate(159 48)"
            >
              <tspan x={88.283} y={64.521}>
                {`Pay`}
              </tspan>
            </text>
            <text
              fill="#274E53"
              fontFamily="Poppins-SemiBold, Poppins"
              fontSize={36}
              fontWeight={500}
              letterSpacing={-2}
              transform="translate(159 48)"
            >
              <tspan x={30.5} y={109.521}>
                {MonthSavingsGraphicComponent.discount(beforeCost, afterCost)}{`% `}{MonthSavingsGraphicComponent.moreOrLessText(beforeCost, afterCost)}
              </tspan>
            </text>
          </g>
        </g>
      </svg>
    );
  }
}

export default MonthSavingsGraphicComponent;
