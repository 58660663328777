import React, { Component } from 'react';
import ProposalCalendarComponent from './calendar';
import ProposalPanelsComponent from './panels';
import ProposalPowerComponent from './power';
import './style.scss';

class SolarProposalComponent extends Component {
  render() {
    const imgPath = this.props.imageUrl || `../assets/proposal/house-min.jpg`;
    const energyWithoutSolar = this.props.energyCostWithoutSolar.replace('$','');
    const energyWithSolar = this.props.energyCostWithSolar.replace('$','');
    const savings = parseFloat(parseFloat(energyWithoutSolar) - parseFloat(energyWithSolar)).toFixed(2);

    return (
      <div className="solar-proposal-container">
        <div className="solar-proposal">
          <div className="solar-proposal-header">
            <img alt={this.props.alt} src={imgPath} style={{height: '100%', width: '100%'}} />
            <div className="header-description">
              <h1>Your Customized Solar Proposal</h1>
              <h3>{this.props.address}</h3>
            </div>
          </div>
          <div className="proposal-section proposal-section-bmargin proposal-section-padding">
            <div className="proposal-card">
              <ProposalPanelsComponent height={96} />
              <h3>{this.props.numberOfPanels}</h3>
              <h5>Number</h5>
              <h5>of Panels</h5>
            </div>
            <div className="proposal-card">
              <ProposalPowerComponent height={96} />
              <h3>{this.props.systemSize || NaN} kW</h3>
              <h5>Estimated</h5>
              <h5>System Size</h5>
            </div>
            <div className="proposal-card">
              <ProposalCalendarComponent height={96} />
              <h3>{this.props.production || NaN} kWh</h3>
              <h5>Estimated First Year</h5>
              <h5>Annual Production</h5>
            </div>
          </div>
          <div className="solar-proposal-body">
            <table className="proposal-table">
              <tbody>
                <tr>
                  <td>
                    <div className="proposal-card">
                      <h3>{this.props.shading || NaN} %</h3>
                      <h5>Shading</h5>
                    </div>
                  </td>
                  <td>
                    <div className="proposal-card">
                      <h3>{this.props.azimuth || NaN}</h3>
                      <h5>Azimuth</h5>
                    </div>
                  </td>
                  <td>
                    <div className="proposal-card">
                      <h3>{this.props.slope || NaN} / 12</h3>
                      <h5>Roof Pitch</h5>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>
                    <div className="proposal-card">
                      <h3>{this.props.panelSize || NaN} w</h3>
                      <h5>Panel Size</h5>
                    </div>
                  </td>
                  <td>
                    <div className="proposal-card">
                      <h3>{this.props.derate || NaN} %</h3>
                      <h5>System Derate Factor</h5>
                    </div>
                  </td>
                  <td>
                    <div className="proposal-card">
                      <h3>{this.props.escalator || NaN} %</h3>
                      <h5>Electricity Escalator</h5>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
            <h1>Financing</h1>
            <hr />
            <h1 className="proposal-title">{this.props.financing}</h1>
            <div className="proposal-section proposal-section-bmargin">
              <div className="proposal-card">
                <h3>{this.props.interestRate || NaN} %</h3>
                <h5>Interest</h5>
                <h5>Rate</h5>
              </div>
              <div className="proposal-card">
                <h3>{this.props.financingYears || NaN} yrs</h3>
                <h5>Terms</h5>
              </div>
              <div className="proposal-card">
                <h3>{this.props.scenarioYears || NaN} yrs</h3>
                <h5>Scenario</h5>
                <h5>Term</h5>
              </div>
            </div>
            <div className="proposal-section proposal-section-bmargin">
              <div className="proposal-section">
                <div className="proposal-card proposal-card-full-width proposal-card-padding proposal-card-border">
                  <h1>{this.props.monthlySavings}</h1>
                  <h2>Estimated Monthly Savings</h2>
                </div>
                <div className="proposal-card proposal-card-filled proposal-card-full-width proposal-card-padding  proposal-card-border">
                  <h1>{this.props.lifetimeSavings}</h1>
                  <h2>Estimated 20 years Savings</h2>
                </div>
              </div>
            </div>
            <hr />
            <h1 className="proposal-title1">Cost of Energy</h1>
            <div className="energy-cost-section">
              <div className="proposal-section proposal-section-bmargin">
                <div className="proposal-section">
                  <div className="proposal-card proposal-card-full-width proposal-card-padding proposal-card-border">
                    <h1>{this.props.energyCostWithoutSolar} /kwh</h1>
                    <h2>Without Solar</h2>
                  </div>
                  <div className="proposal-card proposal-card-filled proposal-card-full-width proposal-card-padding proposal-card-border">
                    <h1>{this.props.energyCostWithSolar} /kwh</h1>
                    <h2>With Solar</h2>
                  </div>
                  <div className="proposal-card proposal-card-full-width proposal-card-padding proposal-card-colored">
                    <h1>{savings} /kwh</h1>
                    <h2>Savings</h2>
                  </div>
                </div>
              </div>
            </div>
            <hr />
            <div className="proposal-section proposal-section-margin">
              <div className="proposal-card">
                <h3>{this.props.costOfDoingNothing}</h3>
                <h5>Cost of doing</h5>
                <h5>nothing</h5>
              </div>
              <div className="proposal-card">
                <h3>{this.props.federalTaxCredits}</h3>
                <h5>Federal Tax</h5>
                <h5>Credits</h5>
              </div>
              <div className="proposal-card">
                <h3>{this.props.netSystemCost}</h3>
                <h5>Estimated System Costs</h5>
                <h5>after tax credits</h5>
              </div>
            </div>
          </div>
        </div>
        <div className="solar-proposal-footer">
          <h1>Proposal {this.props.proposalID}</h1>
          <h1>{this.props.date}</h1>
        </div>
      </div>
    );
  }
}

export default SolarProposalComponent;
