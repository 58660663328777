import ReducerUtilFunctions from "./util";

import {
  UPDATE_LOCATION_COMPONENT_ACTIVE_FLAG,
  UPDATE_LOCATION_COMPONENT_FIELD_ID
} from '../constants/action_types';

const initialState = {
  isActive: false,
  fieldId: ''
};

export default function locationReducer(state = initialState, action) {
  switch(action.type) {
    case UPDATE_LOCATION_COMPONENT_ACTIVE_FLAG: {
      return ReducerUtilFunctions.updateObject(state, { isActive: action.payload });
    }
    case UPDATE_LOCATION_COMPONENT_FIELD_ID: {
      return ReducerUtilFunctions.updateObject(state, { fieldId: action.payload });
    }
    default:
      // ALWAYS have a default case in a reducer
      return state;
  }
}
