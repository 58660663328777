import React, {Component} from 'react';
import DialogComponent from "../../dialog";
import {SAVE_DATA_ACTION} from "../../../redux/actions";
import connect from "react-redux/es/connect/connect";
import './style.scss';

const mapDispatchToProps = dispatch => {
  return {
    saveData: (data) => dispatch(SAVE_DATA_ACTION(data))
  };
};

class ListItemComponent extends Component {

  handleSubmit = (event) =>{
    event.preventDefault();
    const data = [{
      "fieldId" : this.props.fieldID,
      "value": this.props.value
    }];
    this.props.saveData(data);
  };

  render() {
    return (
      <div className="list-item-container" style={{...this.props}}>
        <div className="list-item-row">
          <div className="list-item-title">
            <span>{this.props.title}</span>
          </div>
          <div className="list-item-content">
            <span>{this.props.text}</span>
          </div>
          <div className="list-item-action">
            {
              this.props.moreInformation ?
                <DialogComponent
                  buttonText="i"
                  dismissText="Got It"
                  text={this.props.moreInformation}
                  title="More Information"
                />
                :
                <span className="list-item-info-holder">&nbsp;</span>
            }
            <button
              className="list-item-action-button"
              onClick={this.props.isSubmit ? this.handleSubmit : null}
              type="button"
            >
              {this.props.buttonText}
            </button>
          </div>
        </div>
      </div>
    )
  }
}

export default connect(null, mapDispatchToProps)(ListItemComponent);
