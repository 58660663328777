import React from 'react';
import './style.scss';

const BlockComponent = (props) => {
  return (
    <div style={{...props}}>
      {props.children}
    </div>
  )
};

export default BlockComponent;
