import React, {Component} from 'react'
import {formatMoney} from "../../../common";

class SolarSavingsGraphicComponent extends Component {

  static renderDefinitions() {
    return (
      <React.Fragment>
        <rect id="b" width={757.565} height={152.715} x={31} y={60} rx={76.357} />
        <filter id="a" width="107.9%" height="139.3%" x="-4%" y="-19.6%" filterUnits="objectBoundingBox">
          <feOffset in="SourceAlpha" result="shadowOffsetOuter1" />
          <feGaussianBlur in="shadowOffsetOuter1" result="shadowBlurOuter1" stdDeviation={10}/>
          <feColorMatrix in="shadowBlurOuter1" values="0 0 0 0 1 0 0 0 0 0.925248064 0 0 0 0 0.332110164 0 0 0 1 0"/>
        </filter>
        <path
          id="d"
          d="M14.667 14.667C6.567 14.667 0 23.933 0 35.259v144.148C0 190.733 6.566 200 14.667 200h102.666c8.104 0 14.667-9.267 14.667-20.593V35.26c0-11.326-6.563-20.592-14.667-20.592H14.667z"
        />
        <filter id="c" width="213.6%" height="180.9%" x="-56.8%" y="-40.5%" filterUnits="objectBoundingBox">
          <feOffset in="SourceAlpha" result="shadowOffsetOuter1" />
          <feGaussianBlur in="shadowOffsetOuter1" result="shadowBlurOuter1" stdDeviation={25}/>
          <feColorMatrix in="shadowBlurOuter1" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.214419158 0"/>
        </filter>
      </React.Fragment>
    )
  };

  render() {
    const sizex = this.props.sizex || 820;
    const sizey = this.props.sizey || 280;
    const monthSavings = formatMoney(parseFloat(this.props.savingsMonth));
    const totalSavings = formatMoney(parseFloat(this.props.savingsTotal));

    return (
      <svg xmlnsXlink="http://www.w3.org/1999/xlink" width={sizex} height={sizey} viewBox="0 0 820 280">
        <defs>
          { SolarSavingsGraphicComponent.renderDefinitions() }
        </defs>
        <g fill="none" fillRule="evenodd">
          <g fillRule="nonzero">
            <use fill="#000" filter="url(#a)" xlinkHref="#b" />
            <use fill="#F9F3DF" xlinkHref="#b" />
          </g>
          <g fill="#274E53">
            <text fontFamily="Roboto-Regular, Roboto" fontSize={18} opacity={0.8} transform="translate(73 76)">
              <tspan x={86.696} y={70.993}>
                {`Average`}
              </tspan>
              <tspan x={53.108} y={98.993}>
                {`Monthly Savings`}
              </tspan>
            </text>
            <text fontFamily="Poppins-SemiBold, Poppins" fontSize={36} fontWeight={500} letterSpacing={-2} transform="translate(73 76)">
              <tspan x={119} y={38.42} textAnchor="middle">
                { monthSavings }
              </tspan>
            </text>
          </g>
          <g fill="#274E53">
            <text fontFamily="Roboto-Regular, Roboto" fontSize={18} opacity={0.8} transform="translate(510 76)">
              <tspan x={98.913} y={70.993}>
                {`Total`}
              </tspan>
              <tspan x={52.81} y={98.993}>
                {`Lifetime Savings`}
              </tspan>
            </text>
            <text fontFamily="Poppins-SemiBold, Poppins" fontSize={36} fontWeight={500} letterSpacing={-2} transform="translate(510 76)">
              <tspan x={119} y={38.42} textAnchor="middle">
                { totalSavings }
              </tspan>
            </text>
          </g>
          <g transform="translate(344 30)">
            <use fill="#000" filter="url(#c)" xlinkHref="#d" />
            <use fill="#F9F3DF" xlinkHref="#d" />
            <path
              fill="#FFF"
              d="M14.667 14.667C6.567 14.667 0 23.52 0 34.444V172.89c0 10.927 6.566 19.778 14.667 19.778h102.666c8.104 0 14.667-8.85 14.667-19.778V34.444c0-10.923-6.563-19.777-14.667-19.777H14.667z"
            />
            <path
              fill="#274E53"
              d="M14.667 14.667C6.567 14.667 0 21.267 0 29.333v22h132v-22c0-8.066-6.563-14.666-14.667-14.666H14.667z"
            />
            <path
              fill="#1C2D2F"
              d="M33 33a11 11 0 1 1-22 0c0-6.075 4.925-11 11-11s11 4.925 11 11z"
            />
            <path
              fill="#FFF"
              d="M22 0a7.333 7.333 0 0 0-7.333 7.333v22a7.333 7.333 0 1 0 14.666 0v-22A7.333 7.333 0 0 0 22 0z"
            />
            <path
              fill="#1C2D2F"
              d="M121 33a11 11 0 1 1-22 0c0-6.075 4.925-11 11-11s11 4.925 11 11z"
            />
            <path
              fill="#FFF"
              d="M110 0c-4.048 0-7.333 2.933-7.333 7.333v22c0 3.667 3.285 7.334 7.333 7.334s7.333-3.667 7.333-7.334v-22c0-4.4-3.285-7.333-7.333-7.333z"
            />
            <path fill="#1C2D2F" d="M0 51.333h132v7.333H0z" />
            <g fill="#274E53">
              <text fontFamily="Roboto-Regular, Roboto" fontSize={18} opacity={0.8} transform="translate(6 68)">
                <tspan x={37.531} y={70.993}>
                  {`Years`}
                </tspan>
                <tspan x={5.728} y={98.993}>
                  {`Until Payback`}
                </tspan>
              </text>
              <text fontFamily="Poppins-SemiBold, Poppins" fontSize={36} fontWeight={500} letterSpacing={-2} transform="translate(6 68)">
                <tspan x={59.5} y={38.42} textAnchor="middle">
                  { this.props.savingsYears }
                </tspan>
              </text>
            </g>
          </g>
        </g>
      </svg>
    )
  }
}

export default SolarSavingsGraphicComponent
