import { combineReducers } from "redux";
import apiReducer from './_api_reducer';
import azimuthReducer from './_azimuth_reducer';
import locationReducer from './_location_reducer';
import mapReducer from './_map_reducer';
import navigationReducer from './_navigation_reducer';
import submitReducer from './_submit_reducer';
import validationReducer from './_validation_reducer';

export default combineReducers({
  api: apiReducer,
  azimuthStore: azimuthReducer,
  locationStore: locationReducer,
  map: mapReducer,
  navigation: navigationReducer,
  submitInfo: submitReducer,
  validation: validationReducer
});

/*
const initialState = {
  api: {
    api: 'https://leadgen.inquire.solar/api/v1/sessions',
    isSessionFetched: false,
    isSessionFetching: false,
    isClientIpFetched: false,
    isClientIpFetching: false,
    errorData: null,
    clientIpAddress: '',
    ipFetchError: null,
    session: ''
  },
  azimuthComponent: {
    isActive: false,
    fieldId: '',
    value: null
  },
  locationState: {
    isActive: false,
    fieldId: ''
  },
  map: {
    center: [40.3870835, -111.7521969],
    isDraggable: true,
    size: {
      height: 200,
      width: 100
    }
  },
  navigation: {
    errorData: null,
    isLoading: true,
    loadingNext: false,
    loadingPrevious: false,
    savingData: false,
    verifyingData: false,
    pageData: null,
    pageId: ''
  },
  submitData: [],
  submitInfo: {
    isBypassActive: false,
    bypassAttempts: null,
    submitAttempts: null
  },
  validation: {
    hasErrors: false,
    items: [],
    pageID: null
  }
};
*/


