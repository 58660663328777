import {
  NAVIGATION_UPDATE_PAGE_ID,
  NAVIGATION_UPDATE_PREVIOUS_PAGE_ID,
  TRIGGER_NEXT_PAGE_BEGIN,
  TRIGGER_NEXT_PAGE_SUCCESS,
  TRIGGER_NEXT_PAGE_FAILURE,
  TRIGGER_PREVIOUS_PAGE_BEGIN,
  TRIGGER_PREVIOUS_PAGE_SUCCESS,
  TRIGGER_PREVIOUS_PAGE_FAILURE,
  UPDATE_IS_SAVING_DATA_FLAG,
  UPDATE_IS_VERIFYING_DATA_FLAG,
  TRIGGER_FETCH_PAGE_BEGIN,
  TRIGGER_FETCH_PAGE_SUCCESS,
  TRIGGER_FETCH_PAGE_FAILURE
} from '../constants/_navigation_constants';

export const NAVIGATION_UPDATE_PREVIOUS_PAGE_ID_ACTION = id => ({
  type: NAVIGATION_UPDATE_PREVIOUS_PAGE_ID,
  payload: id
});

export const NAVIGATION_UPDATE_PAGE_ID_ACTION = id => ({
  type: NAVIGATION_UPDATE_PAGE_ID,
  payload: id
});

export const TRIGGER_FETCH_PAGE_BEGIN_ACTION = () => ({
  type: TRIGGER_FETCH_PAGE_BEGIN
});

export const TRIGGER_FETCH_PAGE_SUCCESS_ACTION = data => ({
  type: TRIGGER_FETCH_PAGE_SUCCESS,
  payload: data
});

export const TRIGGER_FETCH_PAGE_FAILURE_ACTION = error => ({
  type: TRIGGER_FETCH_PAGE_FAILURE,
  payload: error
});

export const TRIGGER_NEXT_PAGE_BEGIN_ACTION = () => ({
  type: TRIGGER_NEXT_PAGE_BEGIN
});

export const TRIGGER_NEXT_PAGE_SUCCESS_ACTION = data => ({
  type: TRIGGER_NEXT_PAGE_SUCCESS,
  payload: data
});

export const TRIGGER_NEXT_PAGE_FAILURE_ACTION = error => ({
  type: TRIGGER_NEXT_PAGE_FAILURE,
  payload: error
});

export const TRIGGER_PREVIOUS_PAGE_BEGIN_ACTION = () => ({
  type: TRIGGER_PREVIOUS_PAGE_BEGIN
});

export const TRIGGER_PREVIOUS_PAGE_SUCCESS_ACTION = data => ({
  type: TRIGGER_PREVIOUS_PAGE_SUCCESS,
  payload: data
});

export const TRIGGER_PREVIOUS_PAGE_FAILURE_ACTION = error => ({
  type: TRIGGER_PREVIOUS_PAGE_FAILURE,
  payload: error
});

export const UPDATE_IS_SAVING_DATA_FLAG_ACTION = bool => ({
  type: UPDATE_IS_SAVING_DATA_FLAG,
  payload: bool
});

export const UPDATE_IS_VERIFYING_DATA_FLAG_ACTION = bool => ({
  type: UPDATE_IS_VERIFYING_DATA_FLAG,
  payload: bool
});
