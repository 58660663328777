import {
  APPEND_SUBMIT_DATA,
  REMOVE_SUBMIT_DATA_BY_ID,
  UPDATE_SUBMIT_DATA,
  UPDATE_SUBMIT_DATA_VALUE_BY_ID,
  UPDATE_SUBMIT_DATA_PAGE_ID,
  UPDATE_SUBMIT_DATA_SESSION,
  UPDATE_BYPASS_BOOL_SUBMIT_FLAG,
  UPDATE_BYPASS_ATTEMPTS_COUNT_SUBMIT_FLAG,
  UPDATE_SUBMIT_ATTEMPTS,
  UPDATE_TRUSTED_FORM_CERTIFICATE,
  UPDATE_JORNAYA_TOKEN
} from '../constants/action_types';

export const APPEND_SUBMIT_DATA_ACTION = data => ({
  type: APPEND_SUBMIT_DATA,
  payload: data
});

export const REMOVE_SUBMIT_DATA_BY_ID_ACTION = id => ({
  type: REMOVE_SUBMIT_DATA_BY_ID,
  payload: id
});

export const UPDATE_SUBMIT_DATA_ACTION = data => ({
  type: UPDATE_SUBMIT_DATA,
  payload: data
});

export const UPDATE_SUBMIT_DATA_VALUE_BY_ID_ACTION = obj => ({
  type: UPDATE_SUBMIT_DATA_VALUE_BY_ID,
  payload: Object.assign({}, {
    fieldId: obj.fieldId,
    value: obj.value
  })
});

export const UPDATE_SUBMIT_DATA_PAGE_ID_ACTION = id => ({
  type: UPDATE_SUBMIT_DATA_PAGE_ID,
  payload: id
});

export const UPDATE_SUBMIT_DATA_SESSION_ACTION = session => ({
  type: UPDATE_SUBMIT_DATA_SESSION,
  payload: session
});

export const UPDATE_BYPASS_BOOL_SUBMIT_FLAG_ACTION = bool => ({
  type: UPDATE_BYPASS_BOOL_SUBMIT_FLAG,
  payload: bool
});

export const UPDATE_BYPASS_ATTEMPTS_COUNT_SUBMIT_FLAG_ACTION = number => ({
  type: UPDATE_BYPASS_ATTEMPTS_COUNT_SUBMIT_FLAG,
  payload: number
});

export const UPDATE_SUBMIT_ATTEMPTS_ACTION = number => ({
  type: UPDATE_SUBMIT_ATTEMPTS,
  payload: number
});

export const UPDATE_TRUSTED_FORM_CERTIFICATE_ACTION = certificate => ({
  type: UPDATE_TRUSTED_FORM_CERTIFICATE,
  payload: certificate
});

export const UPDATE_JORNAYA_TOKEN_ACTION = token => ({
  type: UPDATE_JORNAYA_TOKEN,
  payload: token
});
