import React from 'react';
import MaskedInput from 'react-text-mask';
import {
  APPEND_SUBMIT_DATA_ACTION,
  UPDATE_SUBMIT_DATA_VALUE_BY_ID_ACTION,
  REMOVE_SUBMIT_DATA_BY_ID_ACTION,
  UPDATE_BYPASS_ATTEMPTS_COUNT_SUBMIT_FLAG_ACTION,
  UPDATE_SUBMIT_ATTEMPTS_ACTION,
  RESET_VALIDATION_STATE_ACTION,
  VALIDATE_DATA_AND_TRIGGER_SUBMIT_ACTION
} from "../../../redux/actions";
import connect from "react-redux/es/connect/connect";
import emitter from "../../../common/eventemitter";
import './style.scss';

const mapDispatchToProps = dispatch => {
  return {
    appendSubmitData: data => dispatch(APPEND_SUBMIT_DATA_ACTION(data)),
    updateSubmitData: obj => dispatch(UPDATE_SUBMIT_DATA_VALUE_BY_ID_ACTION(obj)),
    removeSubmitData: id => dispatch(REMOVE_SUBMIT_DATA_BY_ID_ACTION(id)),
    updateBypassValue: number => dispatch(UPDATE_BYPASS_ATTEMPTS_COUNT_SUBMIT_FLAG_ACTION(number)),
    updateSubmitAttempts: number => dispatch(UPDATE_SUBMIT_ATTEMPTS_ACTION(number)),
    resetValidationState: () => dispatch(RESET_VALIDATION_STATE_ACTION()),
    validateDataAndSave: (data) => dispatch(VALIDATE_DATA_AND_TRIGGER_SUBMIT_ACTION(data))
  };
};

const mapStateToProps = state => {
  return {
    data: state.submitInfo.submitData.data,
    bypassAttempts: state.submitInfo.bypassAttempts,
    isBypassActive: state.submitInfo.isBypassActive,
    submitAttempts: state.submitInfo.submitAttempts,

    hasErrors: state.validation.hasErrors,
    errors: state.validation.items
  };
};

class PhoneNumberTextBoxComponent extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      inputValue: this.props.value ? this.props.value : '',
      isError: false,
      errorMessage: ''
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.emitterSubscription = null;
  }

  componentDidMount() {
    const obj = {
      fieldId: this.props.fieldID,
      value: this.state.inputValue
    };
    this.props.appendSubmitData(obj);
    this.emitterSubscription = emitter.addListener('validate-local', () => {this.handleLocalValidation()});
  }

  componentWillUnmount() {
    this.props.removeSubmitData(this.props.fieldID);
    this.props.resetValidationState();
    this.props.updateSubmitAttempts(0);
    this.emitterSubscription.remove();
  }

  handleLocalValidation() {
    if (this.state.inputValue === '') {
      this.setState({
        isError: true,
        errorMessage: 'You need to enter a value in order to continue.'
      });
    } else {
      this.setState({
        isError: false,
        errorMessage: ''
      });
    }
  }

  errorMessageFactory() {
    const { errors, hasErrors } = this.props;
    if (this.state.isError) {
      return (
        <div className="textbox-error-message">
          {this.state.errorMessage}
        </div>
      )
    }
    if (hasErrors) {
      if (errors) {
        const firstError = errors.find(item => {
          return item.fieldID === this.props.fieldID && item.errorDescription != null && '' !== item.errorDescription;
        });
        if (firstError) {
          return (
            <div className="textbox-error-message">
              {firstError.errorDescription}
            </div>
          )
        }
      } else {
        return (
          <div className="textbox-error-message">
            Unknown error, missing error description.
          </div>
        )
      }
    }
    return (
      <span>&nbsp;</span>
    )
  }

  handleSubmit = (event) => {
    event.preventDefault();
    this.props.updateSubmitAttempts(this.props.submitAttempts+1);

    const {data} = this.props;
    this.props.validateDataAndSave(data);
  };

  handleChangedData() {
    const obj = {
      fieldId: this.props.fieldID,
      value: this.state.inputValue
    };
    this.props.updateSubmitData(obj);
  }

  handleChange(event) {
    this.setState({
      inputValue: event.target.value
    }, this.handleChangedData);
  }

  handleInputKeyDown = event => {
    if (event.key === 'Enter') {
      this.handleSubmit(event);
    }
  };

  render() {
    const maskArray = ['(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/];
    const tabIndex = this.props.tabIndex ? this.props.tabIndex : "2";

    return (
      <div className="phonenumber-textbox-container" style={{...this.props}}>
        <div className="phonenumber-textbox-element">
          <MaskedInput
            autoFocus={true}
            className="phonenumber-textbox-input"
            guide={false}
            placeholder={this.props.placeholder}
            onChange={this.handleChange}
            onKeyDown={this.handleInputKeyDown}
            tabIndex={tabIndex}
            type="text"
            value={this.state.inputValue}
            mask={maskArray}
          />
        </div>
        { this.errorMessageFactory() }
      </div>
    );
  }

}

export default connect(mapStateToProps, mapDispatchToProps)(PhoneNumberTextBoxComponent);
