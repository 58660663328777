import {
  UPDATE_MAP_ADDRESS,
  UPDATE_MAP_CENTER,
  UPDATE_MAP_DRAG_ABILITY,
  UPDATE_MAP_SIZE,
  UPDATE_MAP_SIZE_FLAG
} from '../constants/action_types';

export const UPDATE_MAP_CENTER_ACTION = center => ({
  type: UPDATE_MAP_CENTER,
  payload: center
});

export const UPDATE_MAP_ADDRESS_ACTION = address => ({
  type: UPDATE_MAP_ADDRESS,
  payload: address
});

export const UPDATE_MAP_DRAG_ABILITY_ACTION = flag => ({
  type: UPDATE_MAP_DRAG_ABILITY,
  payload: flag
});

export const UPDATE_MAP_SIZE_ACTION = size => ({
  type: UPDATE_MAP_SIZE,
  payload: size
});

export const UPDATE_MAP_SIZE_FLAG_ACTION = flag => ({
  type: UPDATE_MAP_SIZE_FLAG,
  payload: flag
});

