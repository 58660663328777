import React, {Component} from 'react';
import './style.scss';

class ImageContainerComponent extends Component {

  render() {
    const style = {
      backgroundColor: this.props.url ? "transparent" : "#F9F3DF",
      backgroundImage: this.props.url ? `url("${this.props.url}")` : 'none'
    };
    return (
      <div className="image-container-component">
        <span className="image-container-component-before" style={{...style}} />
        <div className="image-container-component-children">
          { this.props.children }
        </div>
        {
          this.props.url ?  <span className="image-container-component-after" /> : null
        }
      </div>
    );
  }

}

export default ImageContainerComponent;
