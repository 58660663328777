import React, {Component} from 'react';
import './style.scss';

class ContactItemComponent extends Component {

  render() {
    return (
      <div className="contact-container" style={{...this.props}}>
        <div className="contact-title">
          {this.props.title}
        </div>
        <div className="contact-email">
          {this.props.emailAddress}
        </div>
        <div className="contact-phone">
          {this.props.phoneNumber}
        </div>
      </div>
    )
  }
}

export default ContactItemComponent;
