import ReducerUtilFunctions from "./util";

import {
  UPDATE_MAP_ADDRESS,
  UPDATE_MAP_CENTER,
  UPDATE_MAP_DRAG_ABILITY,
  UPDATE_MAP_SIZE,
  UPDATE_MAP_SIZE_FLAG
} from '../constants/action_types';

const initialState = {
  address: '',
  center: [40.3870835, -111.7521969],
  isDraggable: true,
  isSizeUpdated: false,
  size: {
    height: 200,
    width: 100
  }
};

export default function mapReducer(state = initialState, action) {
  switch(action.type) {
    case UPDATE_MAP_ADDRESS: {
      return ReducerUtilFunctions.updateObject(state, { address: action.payload });
    }
    case UPDATE_MAP_CENTER: {
      return ReducerUtilFunctions.updateObject(state, { center: action.payload });
    }
    case UPDATE_MAP_DRAG_ABILITY: {
      return ReducerUtilFunctions.updateObject(state, { isDraggable: action.payload });
    }
    case UPDATE_MAP_SIZE: {
      return ReducerUtilFunctions.updateObject(state, {
        isSizeUpdated: true,
        size: action.payload
      });
    }
    case UPDATE_MAP_SIZE_FLAG: {
      return ReducerUtilFunctions.updateObject(state, { isSizeUpdated: action.payload });
    }
    default:
      // ALWAYS have a default case in a reducer
      return state;
  }
}
