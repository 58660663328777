import React, {Component} from 'react';
import './style.scss';

class ArticleImageComponent extends Component {
  render() {
    return (
      <div className="article-image-component">
        <picture>
          <source media="(max-width: 600px)" srcSet={`${this.props.url}-square.webp`} type="image/webp" />
          <source media="(max-width: 1023px)" srcSet={`${this.props.url}-rectangle.webp`} type="image/webp" />
          <source media="(max-width: 1440px)" srcSet={`${this.props.url}-large.webp`} type="image/webp" />
          <source media="(min-width: 1441px)" srcSet={`${this.props.url}-xlarge.webp`} type="image/webp" />
          <source media="(max-width: 600px)" srcSet={`${this.props.url}-square.jpg`} type="image/jpeg" />
          <source media="(max-width: 1023px)" srcSet={`${this.props.url}-rectangle.jpg`} type="image/jpeg" />
          <source media="(max-width: 1440px)" srcSet={`${this.props.url}-large.jpg`} type="image/jpeg" />
          <source media="(min-width: 1441px)" srcSet={`${this.props.url}-xlarge.jpg`} type="image/jpeg" />
          <img
            alt={this.props.alt}
            className="article-image-asset"
            src={`${this.props.url}-xlarge.jpg`}
          />
        </picture>
      </div>
    );
  }
}

export default ArticleImageComponent;
