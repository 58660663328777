import React, {Component} from 'react';
import { ReactComponent as BackIcon } from './_back.svg';
import { FETCH_PREVIOUS_PAGE_ACTION } from "../../redux/actions";
import connect from "react-redux/es/connect/connect";
import './style.scss';

const mapDispatchToProps = dispatch => {
  return {
    fetchPreviousPage: (currentPageId) => dispatch(FETCH_PREVIOUS_PAGE_ACTION(currentPageId)),
  };
};

const mapStateToProps = state => {
  return {
    pageId: state.navigation.pageId
  };
};

class BackComponent extends Component {

  moveTo = (ev) => {
    ev.preventDefault();
    this.props.fetchPreviousPage(this.props.pageId);
  };

  render() {
    const styleD = {
      display: 'flex',
      flexDirection: 'row',
      alignContent: 'center',
      alignItems: 'center',
      justifyContent: 'center'
    };
    return (
      <div style={{...styleD, ...this.props}}>
        <button
          className="page-back-button"
          onClick={this.moveTo}
          type="button"
        >
          <BackIcon className='svg-icon' />
          {
            this.props.title ? <div className="back-title">{this.props.title}</div> : null
          }
          {
            this.props.subtitle ? <div className="back-subtitle">{this.props.subtitle}</div> : null
          }
        </button>
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(BackComponent);
