import React, {Component} from 'react';
import './style.scss';
import PageTitleLogoComponent from "../../../components/title_logo";

class ArticleHeaderComponent extends Component {

  render() {
    const style = {
      backgroundColor: this.props.coverUrl ? "transparent" : "#274E53",
      backgroundImage: this.props.coverUrl ? `url("${this.props.coverUrl}")` : 'none'
    };

    return (
      <div className="article-header-container" onClick={this.handleSubmit}>
        <span className="article-header-text-before" style={{...style}} />
        <div className="article-header-text">
          <a href="/" title="Estimate.Solar">
            <PageTitleLogoComponent fillColor="#fff" width={210} height={30} />
          </a>
          <h4 className="article-header-date">{this.props.date}</h4>
          <h1 className="article-header-title">{this.props.title}</h1>
        </div>
        <span className="article-header-text-after" />
      </div>
    )
  }
}

export default ArticleHeaderComponent;
