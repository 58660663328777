import EventEmitter from './eventemitter';

function notify(message, action, type = 'Success', duration = 5000) {
  EventEmitter.emit('toast', {type, action, message, duration})
}

function formatMoney(cp, noopt) {
  const def = noopt ? undefined : {
    style: 'currency',
    currency: 'USD'
  };
  return cp.toLocaleString('en-EN', def)
}

export {
  EventEmitter,
  notify,
  formatMoney
}
