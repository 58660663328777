import ActivityIndicatorComponent from './activity';
import AddressTextBoxComponent from "./input/address";
import AssetDialogComponent from './asset';
import AzimuthComponent from "./map/azimuth";
import MobileAzimuthComponent from "./map/azimuth-mobile";
import BackComponent from "./back";
import BlankComponent from './blank';
import BlockComponent from "./block";
import ButtonComponent from "./button";
import BypassValidationsComponent from "./bypassValidation";
import ChipComponent from "./card/chipcontainer";
import ChipItemComponent from "./card/chipitem";
import ColumnComponent from "./layout/column";
import ContactItemComponent from "./contact";
import DialogComponent from "./dialog";
import EmailTextBoxComponent from './input/email';
import InfoMarkerMapComponent from "./map/infomarker";
import ImageComponent from './image';
import ImageContainerComponent from './image_container';
import ImageOptionComponent from "./option/imageoption";
import LabelComponent from "./label";
import ListItemComponent from "./card/listitem";
import LocationComponent from './map/location';
import LocationWithoutTextComponent from './map/location_mobile';
import MapAnnotationComponent from "./map/marker";
import MapAnnotationComponent1 from "./map/annotation";
import MapComponent from "./map/googlemap";
import MapStreetComponent from "./mapstreet";
import MonthSavingsGraphicComponent from "./graphic/monthsavings/monthsavings";
import OptionItem from './option/optionitem';
import PageTitleComponent from "./title";
import PageTitleLogoComponent from "./title_logo";
import PageViewedComponent from './pageViewed';
import PhoneNumberTextBoxComponent from './input/phonenumber';
import RowComponent from "./layout/row";
import SliderComponent from "./slider";
import SolarPanelCountComponent from './graphic/panels';
import SolarSavingsGraphicComponent from "./graphic/calendarsavings";
import SolarProposalComponent from './proposal';
import StreetViewComponent from "./map/streetview";
import TextBoxComponent from "./input/textbox";
import TokenProviderComponent from "./token";

export const Stock = {
  AssetDialogComponent,
  ActivityIndicatorComponent,
  AddressTextBoxComponent,
  AzimuthComponent,
  MobileAzimuthComponent,
  BackComponent,
  BlankComponent,
  BlockComponent,
  ButtonComponent,
  BypassValidationsComponent,
  ChipComponent,
  ChipItemComponent,
  ColumnComponent,
  ContactItemComponent,
  DialogComponent,
  EmailTextBoxComponent,
  InfoMarkerMapComponent,
  ImageComponent,
  ImageContainerComponent,
  ImageOptionComponent,
  LabelComponent,
  ListItemComponent,
  LocationComponent,
  LocationWithoutTextComponent,
  MapAnnotationComponent,
  MapAnnotationComponent1,
  MapComponent,
  MapStreetComponent,
  MonthSavingsGraphicComponent,
  OptionItem,
  PageTitleComponent,
  PageTitleLogoComponent,
  PageViewedComponent,
  PhoneNumberTextBoxComponent,
  RowComponent,
  SliderComponent,
  SolarPanelCountComponent,
  SolarSavingsGraphicComponent,
  SolarProposalComponent,
  StreetViewComponent,
  TextBoxComponent,
  TokenProviderComponent
};
