import React from 'react'

const ProposalPowerComponent = props => (
  <svg viewBox="0 0 63 96" height={props.height || 96}>
    <defs>
      <filter id="prefix__a" width="143.6%" height="127%" x="-21.8%" y="-13.5%" filterUnits="objectBoundingBox">
        <feOffset dy={2} in="SourceAlpha" result="shadowOffsetOuter1" />
        <feGaussianBlur in="shadowOffsetOuter1" result="shadowBlurOuter1" stdDeviation={2} />
        <feColorMatrix in="shadowBlurOuter1" result="shadowMatrixOuter1" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.5 0" />
        <feMerge>
          <feMergeNode in="shadowMatrixOuter1" />
          <feMergeNode in="SourceGraphic" />
        </feMerge>
      </filter>
      <linearGradient id="prefix__b" x1="100.043%" x2="-.033%" y1="50.02%" y2="50.02%">
        <stop offset="0%" stopColor="#819DA1" />
        <stop offset="100%" stopColor="#295054" />
      </linearGradient>
      <linearGradient id="prefix__c" x1="-.037%" x2="100.186%" y1="50.016%" y2="50.016%">
        <stop offset="0%" stopColor="#819DA1" />
        <stop offset="100%" stopColor="#295054" />
      </linearGradient>
      <linearGradient id="prefix__d" x1="-.03%" x2="99.948%" y1="49.993%" y2="49.993%">
        <stop offset="0%" stopColor="#819DA1" />
        <stop offset="100%" stopColor="#295054" />
      </linearGradient>
      <linearGradient id="prefix__e" x1=".093%" x2="100.289%" y1="49.99%" y2="49.99%">
        <stop offset="0%" stopColor="#819DA1" />
        <stop offset="100%" stopColor="#295054" />
      </linearGradient>
      <linearGradient id="prefix__f" x1="-.02%" x2="100.168%" y1="49.99%" y2="49.99%">
        <stop offset="0%" stopColor="#819DA1" />
        <stop offset="100%" stopColor="#295054" />
      </linearGradient>
    </defs>
    <g fill="none" fillRule="evenodd" filter="url(#prefix__a)" transform="translate(4 1)">
      <path
        fill="url(#prefix__b)"
        d="M37.66 31.78c-.2 0-.39-.06-.56-.17-.38-.26-.54-.74-.38-1.17l4.84-13.55h-5.52a1 1 0 0 1-.93-.62.98.98 0 0 1 .22-1.09L49.21 1.29c.32-.32.81-.39 1.2-.17.39.23.59.68.48 1.12L47.7 15.39h6.21a1.007 1.007 0 0 1 .67 1.76L38.32 31.53a.99.99 0 0 1-.66.25z"
      />
      <path fill="#D3E6F8" d="M17.59 62.31h2.17v25.43h-2.17z" />
      <path
        fill="#FFF"
        d="M19 62.31h-3.5a3.25 3.25 0 0 1-3.25-3.25v-1.19h10v1.19c0 1.8-1.45 3.25-3.25 3.25z"
      />
      <path
        fill="url(#prefix__c)"
        fillRule="nonzero"
        d="M22.25 56.87h-10c-.55 0-1 .45-1 1v1.19a4.24 4.24 0 0 0 2.48 3.85v24.82c0 .55.45 1 1 1s1-.45 1-1h3.02c0 .55.45 1 1 1s1-.45 1-1V62.92a4.24 4.24 0 0 0 2.48-3.85v-1.19a.976.976 0 0 0-.98-1.01zm-1.01 2.19c0 1.24-1 2.24-2.24 2.24h-3.5c-1.24 0-2.24-1.01-2.24-2.24v-.18h7.99v.18h-.01z"
      />
      <path
        fill="#F9F3DF"
        d="M30.79 38.77H3.71A2.71 2.71 0 0 1 1 36.07c0-1.49 1.21-2.71 2.71-2.71h27.08c1.49 0 2.71 1.21 2.71 2.71a2.717 2.717 0 0 1-2.71 2.7z"
      />
      <path
        fill="url(#prefix__d)"
        d="M29.31 38.14H5.19c-.55 0-1 .45-1 1v13.4c0 3.49 2.84 6.34 6.34 6.34h13.46c3.49 0 6.34-2.84 6.34-6.34v-13.4c-.01-.55-.46-1-1.02-1z"
      />
      <path
        fill="#FFF"
        fillRule="nonzero"
        d="M30.79 39.78H3.71C1.66 39.78 0 38.11 0 36.07s1.66-3.71 3.71-3.71h27.08c2.05 0 3.71 1.66 3.71 3.71 0 2.04-1.67 3.71-3.71 3.71zM3.71 34.36c-.94 0-1.7.76-1.7 1.7s.76 1.7 1.7 1.7h27.08c.94 0 1.7-.76 1.7-1.7s-.76-1.7-1.7-1.7H3.71z"
      />
      <path
        fill="url(#prefix__e)"
        d="M4.29.95C2.4.95.85 2.49.85 4.39v11.8c0 .55.45 1 1 1h4.86c.56 0 1-.45 1-1V4.39A3.41 3.41 0 0 0 4.29.95z"
        transform="translate(5 19)"
      />
      <path
        fill="url(#prefix__f)"
        d="M20.21.95a3.44 3.44 0 0 0-3.44 3.44v11.8c0 .55.45 1 1 1h4.86c.55 0 1-.45 1-1V4.39c.01-1.9-1.53-3.44-3.42-3.44z"
        transform="translate(5 19)"
      />
      <path
        fill="#D3E6F8"
        opacity={0.5}
        d="M9.58 36.19h1.79V22.68l-1.79-1.47zM25.45 36.19h1.79V22.68l-1.79-1.47z"
      />
      <path
        fill="#D3E6F8"
        fillRule="nonzero"
        d="M24.27 44.87H10.23c-.55 0-1-.45-1-1s.45-1 1-1h14.05c.55 0 1 .45 1 1s-.45 1-1.01 1zm0 4.11H10.23c-.55 0-1-.45-1-1s.45-1 1-1h14.05c.55 0 1 .45 1 1s-.45 1-1.01 1zm0 4.11H10.23c-.55 0-1-.45-1-1s.45-1 1-1h14.05c.55 0 1 .45 1 1s-.45 1-1.01 1z"
      />
    </g>
  </svg>
);

export default ProposalPowerComponent;
