import {
  APPEND_SUBMIT_DATA,
  REMOVE_SUBMIT_DATA_BY_ID,
  UPDATE_BYPASS_ATTEMPTS_COUNT_SUBMIT_FLAG,
  UPDATE_BYPASS_BOOL_SUBMIT_FLAG,
  UPDATE_SUBMIT_ATTEMPTS,
  UPDATE_SUBMIT_DATA,
  UPDATE_SUBMIT_DATA_VALUE_BY_ID,
  UPDATE_SUBMIT_DATA_PAGE_ID,
  UPDATE_SUBMIT_DATA_SESSION,
  UPDATE_TRUSTED_FORM_CERTIFICATE,
  UPDATE_JORNAYA_TOKEN
} from '../constants/action_types';
import ReducerUtilFunctions from "./util";

const initialState = {
  bypassAttempts: null,
  isBypassActive: false,
  jornayaToken: '',
  pageId: '',
  session: '',
  submitAttempts: 0,
  submitData: {
    data: [],
    pageId: '',
    sessionId: ''
  },
  trustedFormCertificate: ''
};

export default function submitDataReducer(state = initialState, action) {
  switch(action.type) {
    case UPDATE_BYPASS_BOOL_SUBMIT_FLAG: {
      return ReducerUtilFunctions.updateObject(state, { isBypassActive: action.payload });
    }
    case UPDATE_BYPASS_ATTEMPTS_COUNT_SUBMIT_FLAG: {
      return ReducerUtilFunctions.updateObject(state, { bypassAttempts: action.payload });
    }
    case UPDATE_SUBMIT_ATTEMPTS: {
      return ReducerUtilFunctions.updateObject(state, { submitAttempts: action.payload });
    }
    case APPEND_SUBMIT_DATA: {
      return ReducerUtilFunctions.updateObject(state, {
        submitData: ReducerUtilFunctions.updateObject(state.submitData, {
          pageId: state.pageId,
          data: [...state.submitData.data, action.payload],
          sessionId: state.session
        })
      });
    }
    case REMOVE_SUBMIT_DATA_BY_ID: {
      const prunedIds = state.submitData.data.filter(item => {
        // return all the items not matching the action.payload
        return item.fieldId !== action.payload;
      });
      return ReducerUtilFunctions.updateObject(state, {
        submitData: ReducerUtilFunctions.updateObject(state.submitData, {
          data: prunedIds
        })
      });
    }
    case UPDATE_SUBMIT_DATA: {
      return ReducerUtilFunctions.updateObject(state, {
        submitData: ReducerUtilFunctions.updateObject(state.submitData, {
          data: action.payload ? [].concat(action.payload) : []
        })
      });
    }
    case UPDATE_SUBMIT_DATA_VALUE_BY_ID: {
      const objectIndex = state.submitData.data.findIndex(item => {
        return item.fieldId === action.payload.fieldId;
      });
      if (objectIndex > -1) {
        return ReducerUtilFunctions.updateObject(state, {
          submitData: ReducerUtilFunctions.updateObject(state.submitData, {
            data:  [
              ...state.submitData.data.slice(0, objectIndex),
              {
                "fieldId": action.payload.fieldId,
                "value": action.payload.value
              },
              ...state.submitData.data.slice(objectIndex+1)
            ]
          })
        });
      } else {
        return state;
      }
    }
    case UPDATE_SUBMIT_DATA_PAGE_ID: {
      return ReducerUtilFunctions.updateObject(state, {
        pageId: action.payload ? action.payload : ''
      });
    }
    case UPDATE_SUBMIT_DATA_SESSION: {
      return ReducerUtilFunctions.updateObject(state, {
        session: action.payload ? action.payload : ''
      });
    }
    case UPDATE_TRUSTED_FORM_CERTIFICATE: {
      return ReducerUtilFunctions.updateObject(state, {
        trustedFormCertificate: action.payload ? action.payload : ''
      });
    }
    case UPDATE_JORNAYA_TOKEN: {
      return ReducerUtilFunctions.updateObject(state, {
        jornayaToken: action.payload ? action.payload : ''
      });
    }
    default:
      // ALWAYS have a default case in a reducer
      return state;
  }
}
