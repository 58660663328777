import ReducerUtilFunctions from './util';

import {
  TRIGGER_FETCH_CLIENT_IP_BEGIN,
  TRIGGER_FETCH_CLIENT_IP_FAILURE,
  TRIGGER_FETCH_CLIENT_IP_SUCCESS,
  TRIGGER_FETCH_SESSION_BEGIN,
  TRIGGER_FETCH_SESSION_SUCCESS,
  TRIGGER_FETCH_SESSION_FAILURE,
  UPDATE_APP_CAMPAIGN,
  UPDATE_APP_DOMAIN,
  UPDATE_APP_SESSION,
  UPDATE_CLIENT_IP_ADDRESS
} from '../constants/action_types';

const initialState = {
  campaign: '',
  baseUrl: 'https://leadgen.inquire.solar/api/v1/sessions',
  testUrl: 'https://45a8eaf1-790e-485f-9b75-443eb69f0177.mock.pstmn.io/api/v1/sessions',
  domain: 'estimate.solar',
  isSessionFetched: false,
  isSessionFetching: false,
  isClientIpFetched: false,
  isClientIpFetching: false,
  clientIpAddress: '',
  clientIpFetchError: null,
  session: '',
  sessionFetchError: null
};

export default function apiReducer(state = initialState, action) {
  switch(action.type) {
    case TRIGGER_FETCH_CLIENT_IP_BEGIN: {
      return ReducerUtilFunctions.updateObject(state, {
        isClientIpFetched: false,
        isClientIpFetching: true
      });
    }
    case TRIGGER_FETCH_CLIENT_IP_SUCCESS: {
      return ReducerUtilFunctions.updateObject(state, {
        clientIpAddress: action.payload,
        isClientIpFetched: true,
        isClientIpFetching: false
      });
    }
    case TRIGGER_FETCH_CLIENT_IP_FAILURE: {
      return ReducerUtilFunctions.updateObject(state, {
        clientIpFetchError: action.payload,
        isClientIpFetched: false,
        isClientIpFetching: false
      });
    }
    case TRIGGER_FETCH_SESSION_BEGIN: {
      return ReducerUtilFunctions.updateObject(state, {
        isSessionFetched: false,
        isSessionFetching: true
      });
    }
    case TRIGGER_FETCH_SESSION_SUCCESS: {
      return ReducerUtilFunctions.updateObject(state, {
        isSessionFetched: true,
        isSessionFetching: false,
        session: action.payload
      });
    }
    case TRIGGER_FETCH_SESSION_FAILURE: {
      return ReducerUtilFunctions.updateObject(state, {
        isSessionFetched: false,
        isSessionFetching: false,
        sessionFetchError: action.payload
      });
    }
    case UPDATE_APP_CAMPAIGN: {
      return ReducerUtilFunctions.updateObject(state, {
        campaign: action.payload
      });
    }
    case UPDATE_APP_DOMAIN: {
      return ReducerUtilFunctions.updateObject(state, {
        domain: action.payload
      });
    }
    case UPDATE_APP_SESSION: {
      return ReducerUtilFunctions.updateObject(state, {
        session: action.payload
      });
    }
    case UPDATE_CLIENT_IP_ADDRESS: {
      return ReducerUtilFunctions.updateObject(state, {
        clientIpAddress: action.payload
      });
    }
    default:
      // ALWAYS have a default case in a reducer
      return state;
  }
}
