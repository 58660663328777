import React, {Component} from 'react';
import {ReactComponent as BackIcon} from './_back.svg';
import {FETCH_PREVIOUS_PAGE_ACTION} from "../../redux/actions";
import connect from "react-redux/es/connect/connect";
import './style.scss';

const mapDispatchToProps = dispatch => {
  return {
    fetchPreviousPage: (currentPageId) => dispatch(FETCH_PREVIOUS_PAGE_ACTION(currentPageId)),
  };
};

const mapStateToProps = state => {
  return {
    pageId: state.navigation.pageId
  };
};

class PageTitleComponent extends Component {
  moveTo = (ev) => {
    ev.preventDefault();
    this.props.fetchPreviousPage(this.props.pageId);
  };

  render() {
    return (
      <div className="page-title-container" style={{...this.props}}>
        <span
          className="page-title"
          style={{color: this.props.color ? this.props.color : "#274E53"}}>
          {this.props.title}
        </span>
        {
          this.props.hasBackButton ?
            <div className="page-back-btn-container">
              <button onClick={this.moveTo} type="button">
                <BackIcon className='svg-icon'/>
              </button>
            </div>
          :
            null
        }
      </div>
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(PageTitleComponent);
