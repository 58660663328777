// Navigation Constant Actions Names
export const TRIGGER_FETCH_PAGE_BEGIN = "TRIGGER_FETCH_PAGE_BEGIN";
export const TRIGGER_FETCH_PAGE_SUCCESS = "TRIGGER_FETCH_PAGE_SUCCESS";
export const TRIGGER_FETCH_PAGE_FAILURE = "TRIGGER_FETCH_PAGE_FAILURE";
export const TRIGGER_NEXT_PAGE_BEGIN = "TRIGGER_NEXT_PAGE_BEGIN";
export const TRIGGER_NEXT_PAGE_SUCCESS = "TRIGGER_NEXT_PAGE_SUCCESS";
export const TRIGGER_NEXT_PAGE_FAILURE = "TRIGGER_NEXT_PAGE_FAILURE";
export const TRIGGER_PREVIOUS_PAGE_BEGIN = "TRIGGER_PREVIOUS_PAGE_BEGIN";
export const TRIGGER_PREVIOUS_PAGE_SUCCESS = "TRIGGER_PREVIOUS_PAGE_SUCCESS";
export const TRIGGER_PREVIOUS_PAGE_FAILURE = "TRIGGER_PREVIOUS_PAGE_FAILURE";
export const NAVIGATION_UPDATE_PREVIOUS_PAGE_ID = "NAVIGATION_UPDATE_PREVIOUS_PAGE_ID";
export const NAVIGATION_UPDATE_PAGE_ID = "NAVIGATION_UPDATE_PAGE_ID";

export const UPDATE_IS_SAVING_DATA_FLAG = "UPDATE_IS_SAVING_DATA_FLAG";
export const UPDATE_IS_VERIFYING_DATA_FLAG = "UPDATE_IS_VERIFYING_DATA_FLAG";
