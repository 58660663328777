import React, {Component} from 'react';
import { withRouter } from 'react-router-dom';
import './style.scss';

class ArticleHeroComponent extends Component {

  handleSubmit = (event) => {
    event.preventDefault();
    this.props.history.push(`/${this.props.articleLink}`);
  };

  render() {
    const style = {
      backgroundColor: this.props.coverUrl ? "transparent" : "#274E53",
      backgroundImage: this.props.coverUrl ? `url("${this.props.coverUrl}")` : 'none'
    };

    return (
      <React.Fragment>
        <div className="article-hero-container" onClick={this.handleSubmit}>
          <span className="article-hero-text-before" style={{...style}} />
          <div className="article-hero-text">
            <h4 className="article-hero-date">{this.props.date}</h4>
            <h1 className="article-hero-title">{this.props.title}</h1>
            <h3 className="article-hero-snippet">{this.props.snippet}</h3>
          </div>
          <span className="article-hero-text-after" />
        </div>
      </React.Fragment>
    )
  }
}

export default withRouter(ArticleHeroComponent);
