import {
  UPDATE_LOCATION_COMPONENT_ACTIVE_FLAG,
  UPDATE_LOCATION_COMPONENT_FIELD_ID
} from '../constants/action_types';

export const UPDATE_LOCATION_COMPONENT_ACTIVE_FLAG_ACTION = flag => ({
  type: UPDATE_LOCATION_COMPONENT_ACTIVE_FLAG,
  payload: flag
});

export const UPDATE_LOCATION_COMPONENT_FIELD_ID_ACTION = id => ({
  type: UPDATE_LOCATION_COMPONENT_FIELD_ID,
  payload: id
});
