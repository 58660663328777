/*global google*/
import React, {Component} from 'react';
import ReactStreetview from 'react-streetview';
import {googleConfig} from "../../../settings";

class StreetViewComponent extends Component {

  constructor(props) {
    super(props);
    this.state = {
      position: {lat: parseFloat(this.props.lat), lng: parseFloat(this.props.lng)},
      pov: {
        heading: 0,
        pitch: 0
      },
      zoom: 0
    }
  };

  componentWillMount() {
    this.calculateHeading();
  }

  calculateHeading() {
    const localThis = this;
    const lat = parseFloat(this.props.lat);
    const lng = parseFloat(this.props.lng);
    const streetViewMaxDistance = 100;
    const point = new google.maps.LatLng(lat, lng);
    const streetViewService = new google.maps.StreetViewService();

    // We get the map's default panorama and set up some defaults.
    // Note that we don't yet set it visible.

    streetViewService.getPanoramaByLocation(point, streetViewMaxDistance, function (streetViewPanoramaData, status) {

      if (status === google.maps.StreetViewStatus.OK) {
        const newPoint = streetViewPanoramaData.location.latLng;
        const newHeading = google.maps.geometry.spherical.computeHeading(newPoint, point);
        // set new heading
        localThis.setState({
          pov: Object.assign({}, {...localThis.state.pov}, {
            heading: newHeading
          })
        });
      } else {
        return null;
        // no street view available in this range, or some error occurred
      }
    });
  }

  render() {
    return (
      <ReactStreetview
        apiKey={googleConfig.apiKey}
        streetViewPanoramaOptions={this.state}
        //onPovChanged={(pov) => {console.log(`pov: `,pov);}}
      />
    )
  }
}

export default StreetViewComponent;
