import ReducerUtilFunctions from "./util";

import {
  NAVIGATION_UPDATE_PAGE_ID,
  TRIGGER_NEXT_PAGE_BEGIN,
  TRIGGER_NEXT_PAGE_SUCCESS,
  TRIGGER_NEXT_PAGE_FAILURE,
  TRIGGER_PREVIOUS_PAGE_BEGIN,
  TRIGGER_PREVIOUS_PAGE_SUCCESS,
  TRIGGER_PREVIOUS_PAGE_FAILURE,
  UPDATE_IS_SAVING_DATA_FLAG,
  UPDATE_IS_VERIFYING_DATA_FLAG,
  TRIGGER_FETCH_PAGE_BEGIN,
  TRIGGER_FETCH_PAGE_SUCCESS,
  TRIGGER_FETCH_PAGE_FAILURE,
  NAVIGATION_UPDATE_PREVIOUS_PAGE_ID
} from '../constants/_navigation_constants';

const initialState = {
  errorData: null,
  isLoading: true,
  loadingNext: false,
  loadingPrevious: false,
  isSavingData: false,
  isVerifyingData: false,
  pageData: null,
  previousPageId: '',
  pageId: ''
};

export default function navigationReducer(state = initialState, action) {
  switch(action.type) {
    case NAVIGATION_UPDATE_PREVIOUS_PAGE_ID: {
      localStorage.setItem('previousPageId', action.payload);
      return ReducerUtilFunctions.updateObject(state, {
        previousPageId: action.payload
      });
    }
    case NAVIGATION_UPDATE_PAGE_ID: {
      return ReducerUtilFunctions.updateObject(state, {
        pageId: action.payload
      });
    }
    case TRIGGER_FETCH_PAGE_BEGIN: {
      return ReducerUtilFunctions.updateObject(state, {
        isLoading: true,
        loadingNext: true
      });
    }
    case TRIGGER_FETCH_PAGE_SUCCESS: {
      return ReducerUtilFunctions.updateObject(state, {
        isLoading: false,
        loadingNext: false,
        pageData: action.payload
      });
    }
    case TRIGGER_FETCH_PAGE_FAILURE: {
      return ReducerUtilFunctions.updateObject(state, {
        errorData: action.payload,
        isLoading: false,
        loadingNext: false
      });
    }
    case TRIGGER_NEXT_PAGE_BEGIN: {
      return ReducerUtilFunctions.updateObject(state, {
        isLoading: true,
        loadingNext: true
      });
    }
    case TRIGGER_NEXT_PAGE_SUCCESS: {
      return ReducerUtilFunctions.updateObject(state, {
        isLoading: false,
        loadingNext: false,
        pageData: action.payload
      });
    }
    case TRIGGER_NEXT_PAGE_FAILURE: {
      return ReducerUtilFunctions.updateObject(state, {
        errorData: action.payload,
        isLoading: false,
        loadingNext: false
      });
    }
    case TRIGGER_PREVIOUS_PAGE_BEGIN: {
      return ReducerUtilFunctions.updateObject(state, {
        isLoading: true,
        loadingPrevious: true
      });
    }
    case TRIGGER_PREVIOUS_PAGE_SUCCESS: {
      return ReducerUtilFunctions.updateObject(state, {
        isLoading: false,
        loadingPrevious: false,
        pageData: action.payload
      });
    }
    case TRIGGER_PREVIOUS_PAGE_FAILURE: {
      return ReducerUtilFunctions.updateObject(state, {
        errorData: action.payload,
        isLoading: false,
        loadingPrevious: false
      });
    }
    case UPDATE_IS_SAVING_DATA_FLAG: {
      return ReducerUtilFunctions.updateObject(state, {
        isLoading: action.payload,
        isSavingData: action.payload
      });
    }
    case UPDATE_IS_VERIFYING_DATA_FLAG: {
      return ReducerUtilFunctions.updateObject(state, {
        isLoading: action.payload,
        isVerifyingData: action.payload
      });
    }
    default:
      // ALWAYS have a default case in a reducer
      return state;
  }
}
