/*global google*/
import React, {Component} from 'react';
import GoogleMap from 'google-map-react';
import NoAPIKey from '../../../../images/no-api-key.svg';
import HelperText from '../../../components/util/helper-text';
import { connect } from 'react-redux';
import { googleConfig } from "../../../settings/";
import {
  UPDATE_MAP_CENTER_ACTION,
  UPDATE_MAP_SIZE_ACTION,
  UPDATE_MAP_SIZE_FLAG_ACTION
} from '../../../redux/actions';
import './style.scss';

const mapDispatchToProps = dispatch => {
  return {
    updateMapCenter: center => dispatch(UPDATE_MAP_CENTER_ACTION(center)),
    updateMapSize: size => dispatch(UPDATE_MAP_SIZE_ACTION(size)),
    updateMapSizeFlag: flag => dispatch(UPDATE_MAP_SIZE_FLAG_ACTION(flag))
  };
};

const mapStateToProps = state => {
  return {
    mapAddress: state.map.address,
    isDraggable: state.map.isDraggable
  };
};

class CoordMapType {
  constructor(tileSize, darkness) {
    this.tileSize = tileSize;
    this.opacity = darkness ? parseInt(darkness)/10 : 0;
  }

  getTile(coord, zoom, ownerDocument) {
    let div = ownerDocument.createElement('div');
    //div.innerHTML = coord;
    div.style.backgroundColor = "#1B1B1B";
    div.style.opacity = ""+this.opacity;
    div.style.width = `${this.tileSize.width}px`;
    div.style.height = `${this.tileSize.height}px`;
    return div;
  }
}

class MapComponent extends Component {

  constructor(props) {
    super(props);
    this.state = {
      address: this.props.lat && this.props.lng ? [this.props.lat, this.props.lng] : this.geocodeAddress(),
      center: this.props.lat && this.props.lng ? [this.props.lat, this.props.lng] : this.geocodeAddress(),
      mapSize: {
        width: null,
        height: null
      }
    }
  }

  componentWillUnmount() {
    this.props.updateMapSizeFlag(false);
  }

  static defaultProps = {
    center: [40.3870835, -111.7521969],
    zoom: 32
  };

  initMap = (map, maps) => {
    let localThis = this;
    this.dispatchUpdateMapCenter(map);
    this.dispatchUpdateMapSize(map);
    if (this.props.darkness && this.props.darkness > 0) {
      map.overlayMapTypes.insertAt(0, new CoordMapType(new maps.Size(256, 256)));
    }
    maps.event.addListener(map, 'center_changed', function() {
      localThis.dispatchUpdateMapCenter(map);
    });
    maps.event.addDomListenerOnce(map, 'idle', () => {
      maps.event.addDomListener(window, 'resize', () => {
        localThis.dispatchUpdateMapSize(map);
      });
    });
  };

  createMapOptions() {
    return {
      fullscreenControl: false,
      mapTypeControl: false,
      mapTypeId: 'satellite',
      tilt: this.props.showPerspective ? 45 : 0,
      overviewMapControl: false,
      scrollwheel: false
    };
  }

  geocodeAddress() {
    let address = this.props.mapAddress;
    let geocoder = new google.maps.Geocoder();
    let localThis = this;

    geocoder.geocode({'address': address}, function (results, status) {
      if (status === 'OK' && results[0] && results[0].geometry && results[0].geometry.location) {
        localThis.setState({
          address: [results[0].geometry.location.lat(), results[0].geometry.location.lng()],
          center: [results[0].geometry.location.lat(), results[0].geometry.location.lng()]
        });
      } else {
        localThis.setState({
          address: this.props.lat && this.props.lng ? [this.props.lat, this.props.lng] : MapComponent.defaultProps.center,
          center: this.props.lat && this.props.lng ? [this.props.lat, this.props.lng] : MapComponent.defaultProps.center,
        });
      }
    });
  }

  dispatchUpdateMapCenter(map) {
    if (map) {
      let {lat, lng} = map.getCenter();
      this.props.updateMapCenter([lat(), lng()]);
    }
  }

  dispatchUpdateMapSize(map) {
    if (map) {
      this.props.updateMapSize({
        height: map.getDiv().offsetHeight,
        width: map.getDiv().offsetWidth
      });
    }
  }

  render() {
    return (
      <div className="googlemap-container" style={{...this.props.style}}>
        {googleConfig.apiKey ? (
          <React.Fragment>
            <GoogleMap
              bootstrapURLKeys={{key: googleConfig.apiKey}}
              center={this.state.center}
              onGoogleApiLoaded={({map, maps}) => this.initMap(map, maps)}
              yesIWantToUseGoogleMapApiInternals
              resetBoundsOnResize={true}
              options={Object.assign({}, this.createMapOptions(), {draggable: this.props.isDraggable})}
              zoom={this.props.zoom}
              // instead of css hover (which sometimes is bad for map markers) (bad means inability to hover on markers placed under other markers)
              // you can use internal GoogleMap component hover algorithm hover algorithm explained at x_distance_hover example
              hoverDistance={ 40 / 2}
            >
            </GoogleMap>
          </React.Fragment>
        ) : (
          <HelperText
            imgSrc={NoAPIKey}
            text="Please Enter Your API Key in the Config"
          />
        )}
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(MapComponent);
