import React, { Component } from 'react';
import {
  UPDATE_BYPASS_BOOL_SUBMIT_FLAG_ACTION,
  UPDATE_BYPASS_ATTEMPTS_COUNT_SUBMIT_FLAG_ACTION,
  UPDATE_SUBMIT_ATTEMPTS_ACTION,
  SAVE_DATA_ACTION,
} from "../../redux/actions";
import connect from "react-redux/es/connect/connect";
import './style.scss';

const mapDispatchToProps = dispatch => {
  return {
    updateFlag: bool => dispatch(UPDATE_BYPASS_BOOL_SUBMIT_FLAG_ACTION(bool)),
    updateValue: number => dispatch(UPDATE_BYPASS_ATTEMPTS_COUNT_SUBMIT_FLAG_ACTION(number)),
    updateSubmitAttempts: number => dispatch(UPDATE_SUBMIT_ATTEMPTS_ACTION(number)),
    saveData: (data, bypassFlag) => dispatch(SAVE_DATA_ACTION(data, bypassFlag))
  };
};

const mapStateToProps = state => {
  return {
    data: state.submitInfo.submitData.data,

    bypassAttempts: state.submitInfo.bypassAttempts,
    isBypassActive: state.submitInfo.isBypassActive,
    submitAttempts: state.submitInfo.submitAttempts
  };
};

class BypassValidationsComponent extends Component {

  componentDidMount() {
    this.props.updateFlag(true);
    this.props.updateValue(this.props.numberOfAttemptsRequired);
  }

  componentWillUnmount() {
    this.props.updateFlag(false);
    this.props.updateValue(null);
    this.props.updateSubmitAttempts(0);
  }

  handleSubmit = (event) => {
    event.preventDefault();
    this.props.updateSubmitAttempts(this.props.submitAttempts+1);

    const {data} = this.props;
    this.props.saveData(data, true);
  };

  render() {
    return this.props.isBypassActive && this.props.submitAttempts >= this.props.bypassAttempts ? (
      <div className="bypass-button-container" style={{...this.props}}>
        <button onClick={this.handleSubmit} type="button">
          {this.props.text}
        </button>
      </div>
    ) : (
      null
    )
  }

}

export default connect(mapStateToProps, mapDispatchToProps)(BypassValidationsComponent);
