import React, {Component} from 'react'
import connect from 'react-redux/es/connect/connect';
import {
  UPDATE_AZIMUTH_COMPONENT_ACTIVE_FLAG_ACTION,
  UPDATE_AZIMUTH_COMPONENT_FIELD_ID_ACTION,
  UPDATE_AZIMUTH_VALUE_ACTION,
  UPDATE_MAP_DRAG_ABILITY_ACTION
} from '../../../redux/actions';

const mapDispatchToProps = dispatch => {
  return {
    setMapDraggableFlag: flag => dispatch(UPDATE_MAP_DRAG_ABILITY_ACTION(flag)),
    // this makes the state aware that we have a AzimuthComponent active so the submit buttons know
    updateAzimuthStoreFlag: flag => dispatch(UPDATE_AZIMUTH_COMPONENT_ACTIVE_FLAG_ACTION(flag)),
    updateAzimuthStoreFieldId: id => dispatch(UPDATE_AZIMUTH_COMPONENT_FIELD_ID_ACTION(id)),
    updateAzimuthStoreValue: value => dispatch(UPDATE_AZIMUTH_VALUE_ACTION(value))
  };
};

const mapStateToProps = state => {
  return {
    mapSize: state.map.size
  };
};

class MobileAzimuthComponent extends Component {
  static epsilon = 4;

  constructor(props) {
    super(props);

    const size_x = 280;
    const size_y = 280;

    const handlerSize = 20;
    const innerRadius = 110-20;
    const outerRadius = 110+10;

    this.statics = { size_x, size_y, handlerSize, innerRadius, outerRadius };

    // Angle in Radian = Angle in Degree * Math.PI / 180
    const propsValue = this.props.value ? parseInt(this.props.value) : 180;
    // Angle in Radian = Angle in Degree * Math.PI / 180
    const adjustedAngle = 360 - 90 - propsValue;

    this.state = {
      angle: adjustedAngle,
      azimuth: propsValue,
      touched: false,
      cx: Math.floor((size_x / 2) + 110 * Math.cos(adjustedAngle * Math.PI / 180.0)),
      cy: Math.floor((size_y / 2) + 110 * Math.sin(adjustedAngle * Math.PI / 180.0))
    }
  };

  componentDidMount() {
    window.addEventListener('touchmove', this.preventScrolling, { passive: false });
    this.props.setMapDraggableFlag(false);
    this.props.updateAzimuthStoreFlag(true);
    this.props.updateAzimuthStoreFieldId(this.props.fieldID);
    this.props.updateAzimuthStoreValue(180);
  }

  componentWillUnmount() {
    window.removeEventListener('touchmove', this.preventScrolling);
    this.props.setMapDraggableFlag(true);
    this.props.updateAzimuthStoreFlag(false);
    this.props.updateAzimuthStoreFieldId('');
    this.props.updateAzimuthStoreValue(null);
  }

  preventScrolling = (event) => {
    const { touched } = this.state;
    if (touched) {
      event.preventDefault();
      event.stopPropagation();
    }
  };

  handleClick = (event) => {
    event.stopPropagation();
    const { size_x, size_y, innerRadius, outerRadius } = this.statics;
    const { x: xBlock, y: yBlock } = event.currentTarget.getBoundingClientRect();
    const x = event.clientX - xBlock - size_x / 2;
    const y = event.clientY - yBlock- size_y / 2;
    const angle = -Math.atan2(y, x);

    const outX = Math.abs(Math.floor((outerRadius) * Math.cos(angle)));
    const outY = Math.abs(Math.floor((outerRadius) * Math.sin(angle)));

    const inX = Math.abs(Math.floor((innerRadius) * Math.cos(angle)));
    const inY = Math.abs(Math.floor((innerRadius) * Math.sin(angle)));

    if (
      (Math.abs(x) >= inX - MobileAzimuthComponent.epsilon && Math.abs(x) <= outX + MobileAzimuthComponent.epsilon) ||
      (Math.abs(y) >= inY - MobileAzimuthComponent.epsilon && Math.abs(y) <= outY + MobileAzimuthComponent.epsilon)
    ) {
      this.updateHandlerPosition(angle);
    }
  };

  handleDown = (event) => {
    event.stopPropagation();
    this.setState({ touched: true });
  };

  handleUp = (event) => {
    event.stopPropagation();
    this.setState({ touched: false });
  };

  handleMove = (event) => {
    event.preventDefault();
    event.stopPropagation();

    const { size_x, size_y } = this.statics;
    const { touched } = this.state;

    if (touched) {
      const { x: xBlock, y: yBlock } = event.currentTarget.getBoundingClientRect();

      const evt = event.type === 'touchmove' ? event.touches[0] || event.changedTouches[0] : event;
      const x = evt.clientX - xBlock - size_x / 2;
      const y = evt.clientY - yBlock - size_y / 2;

      const angle = -Math.atan2(y, x);

      this.updateHandlerPosition(angle);
    }
  };

  updateHandlerPosition = (angle) => {
    const { size_x, size_y, innerRadius, handlerSize } = this.statics;
    /*
    const calculatedAzimuth = angle <= 0 ? -angle * 180 / Math.PI : (2 * Math.PI - angle) * 180 / Math.PI;
    const azimuth = Math.floor(calculatedAzimuth);
    */
    const adjustedAngle =  angle * 180/Math.PI - 90;
    const azimuth = Math.floor(adjustedAngle < 0 ? 360 + adjustedAngle : adjustedAngle);

    this.props.updateAzimuthStoreValue(azimuth);

    this.setState({
      angle: 360 - 90 - azimuth,
      azimuth: azimuth,
      cx: Math.floor((size_x / 2) + (innerRadius + handlerSize) * Math.cos(angle)),
      cy: Math.floor((size_y / 2) - (innerRadius + handlerSize) * Math.sin(angle))
    });
  };

  renderHandle({ onHandleDown, cx, cy }) {
    const angle = this.state.angle ? this.state.angle : 0;
    const translate = `translate(${cx-37},${cy-37})`;
    const transform = `translate(3, 0) rotate(${angle+90}, 34, 34)`;
    return (
      <g
        onMouseDown={onHandleDown}
        onTouchStart={onHandleDown}
        transform={translate}
      >
        <path
          fill="#F6CA43"
          d="M34.005 54.093c-17.863 0-28.448 10.21-34 14l17.806-34.26L34.005.092l17.228 33.74 16.772 34.26c-6.312-3.987-16.137-14-34-14z"
          transform={transform}
        />
      </g>
    );
  }

  static renderLine({ cx, cy }) {
    const line_path = `M137 144L${cx} ${cy}`;
    return (
      <path d={line_path} stroke="#F7CA43" strokeWidth={3} opacity={0.5} fillRule="nonzero" />
    );
  }

  static renderDefinitions() {
    return (
      <React.Fragment>
        <style>{`.fill-cls{fill:#f7ca43}`}</style>
        <linearGradient id="path-gradient" x1="50%" x2="50.49%" y1="0%" y2="100%">
          <stop offset="0%" stopColor="#DD5B50" />
          <stop offset="7.928%" stopColor="#E98355" />
          <stop offset="17.687%" stopColor="#DE7B4D" />
          <stop offset="29.649%" stopColor="#E8B153" />
          <stop offset="74.843%" stopColor="#E3CB57" />
          <stop offset="100%" stopColor="#00D801" />
        </linearGradient>
      </React.Fragment>
    )
  };

  render() {
    const { cx, cy } = this.state;
    const { height, width } = this.props.mapSize;
    const shapex = this.props.sizex || 280;
    const shapey = this.props.sizey || 280;

    return (
      <div style={{position: 'relative', zIndex: 3, top: -(height/2 + shapey/2), paddingLeft: (width/2 - shapex/2)}}>
        <div style={{position: 'absolute'}}>
          <svg
            viewBox="0 0 280 280"
            width={shapex}
            height={shapey}
            onMouseMove={this.handleMove}
            onMouseUp={this.handleUp}
            onTouchEnd={this.handleUp}
            onTouchMove={this.handleMove}
            onClick={this.handleClick}
            xmlnsXlink="http://www.w3.org/1999/xlink"
          >
            <defs>
              { MobileAzimuthComponent.renderDefinitions() }
            </defs>

            <g transform="translate(0 0)" fill="none" fillRule="evenodd">
              <g transform="translate(107 114)">
                <circle cx={30} cy={30} r={20} stroke="#F7CA43" strokeWidth={3} opacity={0.5} />
                <circle cx={30} cy={30} r={5} fill="#F7CA43" />
              </g>
              {
                this.props.gradientEnabled ? (
                  <circle cx={137} cy={144} r={110} stroke="#F7CA43" strokeWidth={5} opacity={0.3} />
                ) : (
                  <circle cx={137} cy={144} r={110} stroke="url(#path-gradient)" strokeWidth={5} opacity={0.8} />

                )
              }
              {
                /*
                <path stroke="#979797" d="M5.5 5.5h513v511H5.5z" />
                */
              }
              {MobileAzimuthComponent.renderLine({cx, cy})}
              {this.renderHandle({ onHandleDown: this.handleDown, cx, cy })}
            </g>
          </svg>
        </div>
      </div>
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(MobileAzimuthComponent);
