import React from 'react';

const ColumnComponent = (props) => {
  const style = {
    display: 'flex',
    flexDirection: 'column',
    minHeight: 300,
    overflow: 'auto'
  };
  return (
    <div style={{...style, ...props}}>
      {props.children}
    </div>
  );
};

export default ColumnComponent;
