import {
  UPDATE_AZIMUTH_COMPONENT_ACTIVE_FLAG,
  UPDATE_AZIMUTH_COMPONENT_FIELD_ID,
  UPDATE_AZIMUTH_VALUE
} from '../constants/action_types';

export const UPDATE_AZIMUTH_COMPONENT_ACTIVE_FLAG_ACTION = flag => ({
  type: UPDATE_AZIMUTH_COMPONENT_ACTIVE_FLAG,
  payload: flag
});

export const UPDATE_AZIMUTH_COMPONENT_FIELD_ID_ACTION = id => ({
  type: UPDATE_AZIMUTH_COMPONENT_FIELD_ID,
  payload: id
});

export const UPDATE_AZIMUTH_VALUE_ACTION = value => ({
  type: UPDATE_AZIMUTH_VALUE,
  payload: value
});
