import React, {Component} from 'react';
import EngineButtonComponent from "../engine";
import { ShareButton } from "react-custom-share";
import { SocialIcon } from 'react-social-icons';
import './style.scss';

class BlogFooterComponent extends Component {

  handleShare = (event) =>{
    event.preventDefault();
  };

  render() {
    const shareButtonProps = {
      url: "https://estimate.solar",
      network: "Facebook",
      text: "Solar Panels Calculator and Solar Industry News",
      longtext:
        "Find out how many solar panels you need for your home! Start today, get your solar panels for no money upfront and enjoy the benefits of renewable energy!"
    };
    return (
      <div className="blog-footer-container" style={{...this.props}}>
        <div className="blog-footer-top-section">
          <EngineButtonComponent />
        </div>
        <div className="blog-footer-bottom-section">
          <ShareButton className="btn-secondary" {...shareButtonProps}>
            Share
          </ShareButton>
        </div>
        <div className="blog-footer-social-section">
          <SocialIcon bgColor="#274E53" network="linkedin" url="https://www.linkedin.com/in/estimate-solar-974627173" rel="noopener noreferrer" target="_blank" />
          <SocialIcon bgColor="#274E53" network="twitter" url="https://twitter.com/EstimateSolar1" rel="noopener noreferrer" target="_blank" />
          <SocialIcon bgColor="#274E53" network="facebook" url="https://facebook.com/Estimate-Solar-555687508281879" rel="noopener noreferrer" target="_blank" />
        </div>
      </div>
    )
  }
}

export default BlogFooterComponent;
