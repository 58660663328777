import {Component} from "react";
import BlankComponent from "../../../components/blank";
import DynamicBlock from "./block";
import React from "react";
import {Helmet} from "react-helmet";
import uuid4 from 'uuid4';

class DynamicArticlePage extends Component {

  get id() {
    return this.props.article.id;
  }

  get coverUrl() {
    return this.props.article.coverUrl;
  }

  get date() {
    return this.props.article.date;
  }

  get description() {
    return this.props.article.description;
  }

  get blocks() {
    return this.props.article.content;
  }

  get title() {
    return this.props.article.title;
  }

  get ogTitle() {
    return this.props.article.ogTitle;
  }

  get ogDescription() {
    return this.props.article.ogDescription;
  }

  get ogUrl() {
    return this.props.article.ogUrl;
  }

  get renderBlocks() {
    if (this.blocks) {
      return this.blocks.map(
        b => <DynamicBlock key={uuid4()} root={b} coverUrl={this.coverUrl} date={this.date} />
      );
    } else {
      return (
        <BlankComponent key={uuid4()} />
      )
    }

  }

  render() {
    return (
      <React.Fragment>
        <Helmet>
          <title>{this.title}</title>
          <meta name='description' content={this.description} />
          <meta name='theme-color' content='#F9F3DF' />
          <meta name="p:domain_verify" content="f5efebeb1fc35871fd85fef2746c94b0" />
          {
            // Facebook metadata
          }
          <meta property="og:image:width" content="1640" />
          <meta property="og:image:height" content="859" />
          <meta property="og:title" content={this.ogTitle} />
          <meta property="og:description" content={this.ogDescription} />
          <meta property="og:image" content="http://estimate.solar/assets/facebook/og-image.jpg" />
          <meta property="og:url" content={this.ogUrl} />
        </Helmet>
        {
          this.renderBlocks
        }
      </React.Fragment>
    );
  }
}

export default DynamicArticlePage;
