import React from 'react';
import './style.scss';

const LabelComponent = (props) => {
  switch(props.type) {
    case 'h1': return <h1 style={{...props}}>{props.text}</h1>;
    case 'h2': return <h2 style={{...props}}>{props.text}</h2>;
    case 'h3': return <h3 style={{...props}}>{props.text}</h3>;
    case 'h4': return <h4 style={{...props}}>{props.text}</h4>;
    case 'h5': return <h5 style={{...props}}>{props.text}</h5>;
    case 'h6': return <h6 style={{...props}}>{props.text}</h6>;
    default: return <h1>{props.text}</h1>;
  }
};

export default LabelComponent;
