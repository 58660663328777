import React, {Component} from 'react'
import connect from 'react-redux/es/connect/connect';
import {
  UPDATE_AZIMUTH_COMPONENT_ACTIVE_FLAG_ACTION,
  UPDATE_AZIMUTH_COMPONENT_FIELD_ID_ACTION,
  UPDATE_AZIMUTH_VALUE_ACTION,
  UPDATE_MAP_DRAG_ABILITY_ACTION
} from '../../../redux/actions';

const mapDispatchToProps = dispatch => {
  return {
    setMapDraggableFlag: flag => dispatch(UPDATE_MAP_DRAG_ABILITY_ACTION(flag)),
    // this makes the state aware that we have a AzimuthComponent active so the submit buttons know
    updateAzimuthStoreFlag: flag => dispatch(UPDATE_AZIMUTH_COMPONENT_ACTIVE_FLAG_ACTION(flag)),
    updateAzimuthStoreFieldId: id => dispatch(UPDATE_AZIMUTH_COMPONENT_FIELD_ID_ACTION(id)),
    updateAzimuthStoreValue: value => dispatch(UPDATE_AZIMUTH_VALUE_ACTION(value))
  };
};

const mapStateToProps = state => {
  return {
    mapSize: state.map.size,
    mapSizeUpdated: state.map.isSizeUpdated
  };
};

class AzimuthComponent extends Component {
  static epsilon = 10;

  constructor(props) {
    super(props);

    // Angle in Radian = Angle in Degree * Math.PI / 180
    const propsValue = this.props.value ? parseInt(this.props.value) : 180;
    // Angle in Radian = Angle in Degree * Math.PI / 180
    const adjustedAngle = 360 - 90 - propsValue;

    let containerWidth = 350;
    if (window.matchMedia) {
      if (window.matchMedia("(max-width: 350px)").matches) {
        /* The viewport is less than, or equal to, 700 pixels wide */
        containerWidth = 300;
      } else if (window.matchMedia("(max-width: 550px)").matches) {
        containerWidth = 350;
      } else if (window.matchMedia("(max-width: 900px)").matches){
        containerWidth = 450;
      } else {
        containerWidth = 550;
      }
    }

    const innerRadius = Math.floor(containerWidth / 2 * 0.6);
    const outerRadius = Math.floor(containerWidth / 2 * 0.9);

    this.state = {
      angle: adjustedAngle || 0,
      azimuth: propsValue,
      az_container_width: containerWidth,
      az_handler_size: 34,
      az_handler_inner_radius: innerRadius,
      az_handler_outer_radius: outerRadius,
      touched: false,
      cx: Math.floor(containerWidth/2 + (innerRadius + 34) * Math.cos(adjustedAngle * Math.PI / 180.0)),
      cy: Math.floor(containerWidth/2 + (innerRadius + 34) * Math.sin(adjustedAngle * Math.PI / 180.0))
    }
  };

  componentDidMount() {
    window.addEventListener('touchmove', this.preventScrolling, { passive: false });
    this.props.setMapDraggableFlag(false);
    this.props.updateAzimuthStoreFlag(true);
    this.props.updateAzimuthStoreFieldId(this.props.fieldID);
    this.props.updateAzimuthStoreValue(180);
  }

  componentWillUnmount() {
    window.removeEventListener('touchmove', this.preventScrolling);
    this.props.setMapDraggableFlag(true);
    this.props.updateAzimuthStoreFlag(false);
    this.props.updateAzimuthStoreFieldId('');
    this.props.updateAzimuthStoreValue(null);
  }

  preventScrolling = (event) => {
    const { touched } = this.state;
    if (touched) {
      event.preventDefault();
      event.stopPropagation();
    }
  };

  handleClick = (event) => {
    event.stopPropagation();
    const { az_container_width, az_handler_inner_radius, az_handler_outer_radius } = this.state;
    const { x: xBlock, y: yBlock } = event.currentTarget.getBoundingClientRect();
    const x = event.clientX - xBlock - az_container_width / 2;
    const y = event.clientY - yBlock- az_container_width / 2;
    const angle = -Math.atan2(y, x);

    const outX = Math.abs(Math.floor((az_handler_outer_radius) * Math.cos(angle)));
    const outY = Math.abs(Math.floor((az_handler_outer_radius) * Math.sin(angle)));

    const inX = Math.abs(Math.floor((az_handler_inner_radius) * Math.cos(angle)));
    const inY = Math.abs(Math.floor((az_handler_inner_radius) * Math.sin(angle)));

    if (
      (Math.abs(x) >= inX - AzimuthComponent.epsilon && Math.abs(x) <= outX + AzimuthComponent.epsilon) ||
      (Math.abs(y) >= inY - AzimuthComponent.epsilon && Math.abs(y) <= outY + AzimuthComponent.epsilon)
    ) {
      this.updateHandlerPosition(angle);
    }
  };

  handleDown = (event) => {
    event.stopPropagation();
    this.setState({ touched: true });
  };

  handleUp = (event) => {
    event.stopPropagation();
    this.setState({ touched: false });
  };

  handleMove = (event) => {
    event.preventDefault();
    event.stopPropagation();

    const { az_container_width, touched } = this.state;

    if (touched) {
      const { x: xBlock, y: yBlock } = event.currentTarget.getBoundingClientRect();

      const evt = event.type === 'touchmove' ? event.touches[0] || event.changedTouches[0] : event;
      const x = evt.clientX - xBlock - az_container_width / 2;
      const y = evt.clientY - yBlock - az_container_width / 2;

      const angle = -Math.atan2(y, x);

      this.updateHandlerPosition(angle);
    }
  };

  updateHandlerPosition = (angle) => {
    const { az_container_width, az_handler_inner_radius, az_handler_size } = this.state;
    /*
    const calculatedAzimuth = angle <= 0 ? -angle * 180 / Math.PI : (2 * Math.PI - angle) * 180 / Math.PI;
    const azimuth = Math.floor(calculatedAzimuth);
    */
    const adjustedAngle =  angle * 180/Math.PI - 90;
    const azimuth = Math.floor(adjustedAngle < 0 ? 360 + adjustedAngle : adjustedAngle);

    this.props.updateAzimuthStoreValue(azimuth);

    this.setState({
      angle: 360 - 90 - azimuth,
      azimuth: azimuth,
      cx: Math.floor((az_container_width / 2) + (az_handler_inner_radius + az_handler_size) * Math.cos(angle)),
      cy: Math.floor((az_container_width / 2) - (az_handler_inner_radius + az_handler_size) * Math.sin(angle))
    });
  };

  renderHandle({ onHandleDown, cx, cy }) {
    const { angle, az_handler_size } = this.state;
    const handlerPath = `M0,${az_handler_size} C11,24 23,24 ${az_handler_size},${az_handler_size} L${az_handler_size/2},0z`;
    const translate = `translate(${cx-az_handler_size/2},${cy-az_handler_size/2})`;
    const transform = `rotate(${angle+90}, ${az_handler_size/2}, ${az_handler_size/2})`;
    return (
      <g onMouseDown={onHandleDown} onTouchStart={onHandleDown} transform={translate}>
        <path fill="#F6CA43" d={handlerPath} transform={transform} />
      </g>
    );
  }

  renderLine({ cx, cy }) {
    const { az_container_width} = this.state;
    const { az_center_x, az_center_y } = { az_center_x: az_container_width/2, az_center_y: az_container_width/2 };
    const line_path = `M${az_center_x} ${az_center_y}L${cx} ${cy}`;
    return (
      <path stroke="#F7CA43" strokeLinecap="round" strokeWidth={4} opacity={0.45} d={line_path} />
    );
  }

  static renderDefinitions() {
    return (
      <React.Fragment>
        <linearGradient id="path-gradient" x1="50%" x2="50.49%" y1="0%" y2="100%">
          <stop offset="0%" stopColor="#DD5B50" />
          <stop offset="7.928%" stopColor="#E98355" />
          <stop offset="17.687%" stopColor="#DE7B4D" />
          <stop offset="29.649%" stopColor="#E8B153" />
          <stop offset="74.843%" stopColor="#E3CB57" />
          <stop offset="100%" stopColor="#00D801" />
        </linearGradient>
      </React.Fragment>
    )
  };

  render() {
    const { az_container_width, cx, cy } = this.state;
    const { az_center_x, az_center_y } = { az_center_x: az_container_width/2, az_center_y: az_container_width/2 };
    const { height, width } = this.props.mapSize;
    const { container_center_x, container_center_y } = { container_center_x: width/2, container_center_y: height/2 };
    const az_circle_radius = az_container_width*0.8 / 2;
    const svg_viewport = `0 0 ${az_container_width} ${az_container_width}`;

    return (
      <div style={{position: 'relative', zIndex: 3, top: -(container_center_y + az_container_width/2), paddingLeft: (container_center_x - az_container_width/2)}}>
        <div style={{position: 'absolute'}}>
          <svg
            height={az_container_width}
            onMouseMove={this.handleMove}
            onMouseUp={this.handleUp}
            onTouchEnd={this.handleUp}
            onTouchMove={this.handleMove}
            onClick={this.handleClick}
            viewBox={svg_viewport}
            width={az_container_width}
            xmlnsXlink="http://www.w3.org/1999/xlink"
          >
            <defs>
              { AzimuthComponent.renderDefinitions() }
            </defs>
            <g fill="none">
              <circle cx={az_center_x} cy={az_center_y} r={30} stroke="#F7CA43" strokeWidth={4} opacity={0.45} />
              <circle cx={az_center_x} cy={az_center_y} r={15} fill="#F7CA43" />
              {
                this.props.gradientEnabled ? (
                  <circle cx={az_center_x} cy={az_center_y} r={az_circle_radius} stroke="#F7CA43" strokeWidth={5} opacity={0.3} />
                ) : (
                  <circle cx={az_center_x} cy={az_center_y} r={az_circle_radius} stroke="url(#path-gradient)" strokeWidth={5} opacity={0.8} />
                )
              }
              {this.renderLine({cx, cy})}
              {this.renderHandle({ onHandleDown: this.handleDown, cx, cy })}
            </g>
          </svg>
        </div>
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(AzimuthComponent);
