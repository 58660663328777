import ReducerUtilFunctions from "./util";

import {
  APPEND_VALIDATION_ITEM,
  UPDATE_VALIDATION_HAS_ERRORS_FLAG,
  UPDATE_VALIDATION_ITEMS,
  UPDATE_VALIDATION_PAGE_ID,
  RESET_VALIDATION_STATE,
  UPDATE_VALIDATION_STATE
} from '../constants/action_types';

const initialState = {
  hasErrors: false,
  items: [],
  pageID: null
};

export default function validationReducer(state = initialState, action) {
  switch(action.type) {
    case APPEND_VALIDATION_ITEM: {
      return ReducerUtilFunctions.updateObject(state, {
        items: [...state.items, action.payload]
      });
    }
    case UPDATE_VALIDATION_HAS_ERRORS_FLAG: {
      return ReducerUtilFunctions.updateObject(state, {
        hasError: action.payload
      });
    }
    case UPDATE_VALIDATION_ITEMS: {
      return ReducerUtilFunctions.updateObject(state, {
        items: action.payload ? [].concat(action.payload) : []
      });
    }
    case UPDATE_VALIDATION_PAGE_ID: {
      return ReducerUtilFunctions.updateObject(state, {
        pageID: action.payload
      });
    }
    case RESET_VALIDATION_STATE: {
      return ReducerUtilFunctions.updateObject(state, {
        hasErrors: false,
        items: [],
        pageID: null
      });
    }
    case UPDATE_VALIDATION_STATE: {
      return ReducerUtilFunctions.updateObject(state, action.payload);
    }
    default:
      // ALWAYS have a default case in a reducer
      return state;
  }
}
