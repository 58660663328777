import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import './style.scss';

class EngineButtonComponent extends Component {

  onClick = (event) => {
    event.stopPropagation();
    this.props.history.push('/engine');
  };

  onClose = (event) => {
    event.stopPropagation();
    this.props.history.push('/');
  };

  render() {
    return (
      <div className="engine-container" style={{...this.props}}>
        <button onClick={this.onClick} type="button">
          Start The Solar Calculator
        </button>
      </div>
    );
  }

}

export default withRouter(EngineButtonComponent);

