import React, {Component} from 'react';
import {
  SAVE_DATA_ACTION,
  UPDATE_SUBMIT_ATTEMPTS_ACTION,
  VALIDATE_DATA_ACTION,
  VALIDATE_DATA_AND_TRIGGER_SUBMIT_ACTION
} from "../../redux/actions";
import connect from "react-redux/es/connect/connect";
import emitter from '../../common/eventemitter';
import './style.scss';

const mapDispatchToProps = dispatch => {
  return {
    saveData: (data) => dispatch(SAVE_DATA_ACTION(data)),
    updateSubmitAttempts: number => dispatch(UPDATE_SUBMIT_ATTEMPTS_ACTION(number)),
    validateData: (data) => dispatch(VALIDATE_DATA_ACTION(data)),
    validateDataAndSave: (data) => dispatch(VALIDATE_DATA_AND_TRIGGER_SUBMIT_ACTION(data))
  };
};

const mapStateToProps = state => {
  return {
    data: state.submitInfo.submitData.data,

    submitAttempts: state.submitInfo.submitAttempts,
    azimuthId: state.azimuthStore.fieldId,
    azimuthValue: state.azimuthStore.value,
    isAzimuthActive: state.azimuthStore.isActive,
    locationId: state.locationStore.fieldId,
    isLocationActive: state.locationStore.isActive,
    mapCenter: state.map.center,

    hasErrors: state.validation.hasErrors,
    errors: state.validation.items
  };
};

class ButtonComponent extends Component {

  handleValidation(callback) {
    let localArguments = arguments;
    const { data } = this.props;

    this.props.validateData(data).then(() => {
      if (localArguments.length > 0) {
        callback();
      }
    });
  }

  handleSubmit = (event) => {
    event.preventDefault();
    const { azimuthId, azimuthValue, isAzimuthActive } = this.props;
    const { locationId, isLocationActive } = this.props;
    let { data } = this.props;

    if (isAzimuthActive) {
      const azimuthData = [...data, {
        "fieldId" : azimuthId,
        "value": azimuthValue
      }];
      this.props.saveData(azimuthData);
    } else if (isLocationActive) {
      const locationData = [...data, {
        "fieldId" : locationId,
        "value": `(${this.props.mapCenter[0]},${this.props.mapCenter[1]})`
      }];
      this.props.saveData(locationData);
    } else {
      emitter.emit('validate-local');
      this.props.updateSubmitAttempts(this.props.submitAttempts+1);
      this.props.validateDataAndSave(data);
    }
  };

  render() {
    return (
      <div className="button-container" style={{...this.props}}>
        <button
          className={this.props.className || 'btn-secondary'}
          onClick={this.props.onClick ? this.props.onClick : (this.props.isSubmit ? this.handleSubmit : null)}
          style={{...this.props}}
          type="button"
        >
          {this.props.text}
        </button>
      </div>
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ButtonComponent);
