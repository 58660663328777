import React, {Component} from 'react';
import {Helmet} from 'react-helmet';
import ActivityIndicatorComponent from '../../components/activity';
import BlankComponent from "../../components/blank";
import Section from '../section';
import connect from "react-redux/es/connect/connect";

const mapStateToProps = state => {
  return {
    page: state.navigation.pageData,
    isLoading: state.navigation.isLoading,
    isVerifyingData: state.navigation.isVerifyingData,
    isSavingData: state.navigation.isSavingData
  };
};

class Page extends Component {

  get activityMessage() {
    return this.props.page.activityIndicator.messages
  }

  get id() {
    return this.props.page.guid;
  }

  get description() {
    return this.props.page.shortTitle;
  }

  get sections() {
    return this.props.page.content;
  }

  get title() {
    return this.props.page.title;
  }

  get meta() {
    return this.props.page.meta;
  }

  get renderSections() {
    if (this.sections) {
      return this.sections.map(
        s => <Section
          key={s.guid}
          root={s}
          sectionId={s.guid}
        />
      );
    } else {
      return (
        <BlankComponent />
      )
    }
  }

  getActivityIndicatorMessage(isPageNull) {
    if (!!isPageNull && this.activityMessage && this.activityMessage.length>0) {
      return this.activityMessage;
    }
    if (this.props.isVerifyingData) {
      return ["Validating Data..."]
    }
    if (this.props.isSavingData) {
      return ["Saving Data..."]
    }
    if (this.props.isLoading) {
      return ["Loading Data..."]
    }
  }

  showActivityIndicator() {
    return this.props.page === null || this.props.isLoading || this.props.isSavingData || this.props.isVerifyingData;
  }

  render() {
    const isPageEmpty = this.props.page === null;
    return (
      <React.Fragment>
        <Helmet>
          <title>{isPageEmpty ? "Solar Estimator" : this.title}</title>
          <meta name='description' content={isPageEmpty ? "Solar Estimator Website" : this.description} />
          <meta name='keywords' content='Solar Panels Calculator,  Solar Industry News,  Solar Energy Pros And Cons, Solar Energy Definition, How do Solar Panels Work, Going Solar, Price of Solar Panels' />
          <meta property="og:image:width" content="1640" />
          <meta property="og:image:height" content="859" />
          <meta property="og:title" content={
            isPageEmpty ? "Solar Panels Calculator and Solar Industry News" : this.meta['ogTag'] || "Solar Panels Calculator and Solar Industry News"
          } />
          <meta property="og:description" content={
            isPageEmpty ? "Find out how many solar panels you need for your home! Start today, get your solar panels for no money upfront and enjoy the benefits of renewable energy!"
              : this.meta['ogDescription'] || "Find out how many solar panels you need for your home! Start today, get your solar panels for no money upfront and enjoy the benefits of renewable energy!"
          } />
          <meta property="og:image" content="http://estimate.solar/assets/facebook/og-image.jpg" />
          <meta property="og:url" content="https://estimate.solar"/>
          <meta name='theme-color' content='#F9F3DF' />
          <meta name="p:domain_verify" content="f5efebeb1fc35871fd85fef2746c94b0" />
        </Helmet>
        {
          !isPageEmpty ? this.renderSections : null
        }
        {
          this.showActivityIndicator() ?
            <ActivityIndicatorComponent message={
              this.getActivityIndicatorMessage(this.props.page != null)
            } />
            :
            null
        }
      </React.Fragment>
    );
  }
}

export default connect(mapStateToProps, null)(Page);
