import React, {Component} from 'react'
import './style.scss';

class SolarPanelCountComponent extends Component {

  static renderDefinitions() {
    return (
      <React.Fragment>
        <circle className="sun-glowing" id="sun_element" cx={193.5} cy={164.5} r={101.5} />
        <filter id="sun_shine_effect" width="173.9%" height="173.9%" x="-36.9%" y="-36.9%" filterUnits="objectBoundingBox">
          <feMorphology in="SourceAlpha" operator="dilate" radius={10} result="shadowSpreadOuter1"/>
          <feOffset in="shadowSpreadOuter1" result="shadowOffsetOuter1" />
          <feGaussianBlur in="shadowOffsetOuter1" result="shadowBlurOuter1" stdDeviation={15}/>
          <feColorMatrix in="shadowBlurOuter1" values="0 0 0 0 1 0 0 0 0 0.931622934 0 0 0 0 0.328993056 0 0 0 1 0"/>
        </filter>
        <linearGradient id="c" x1="64.194%" x2="32.231%" y1="0%" y2="100%">
          <stop offset="0%" stopColor="#85A1A4" />
          <stop offset="100%" stopColor="#274E53" />
        </linearGradient>
        <path id="b" d="M7.876 0h17.848a3.2 3.2 0 0 1 3.138 2.576l3.978 20A3.2 3.2 0 0 1 29.7 26.4H3.899A3.2 3.2 0 0 1 .76 22.576l3.978-20A3.2 3.2 0 0 1 7.876 0z"/>
        <filter id="a" width="134%" height="141.7%" x="-17%" y="-13.3%" filterUnits="objectBoundingBox">
          <feOffset dy={2} in="SourceAlpha" result="shadowOffsetOuter1" />
          <feGaussianBlur in="shadowOffsetOuter1" result="shadowBlurOuter1" stdDeviation={1.5}/>
          <feColorMatrix in="shadowBlurOuter1" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.199077219 0"/>
        </filter>
        <linearGradient id="d" x1="64.194%" x2="33.293%" y1="0%" y2="96.676%">
          <stop offset="0%" stopColor="#A2C2C6" />
          <stop offset="100%" stopColor="#587E83" />
        </linearGradient>
        <linearGradient id="e" x1="64.194%" x2="32.231%" y1="0%" y2="100%">
          <stop offset="0%" stopColor="#A2C2C6" />
          <stop offset="96.676%" stopColor="#587E83" />
        </linearGradient>
      </React.Fragment>
    )
  };

  static addPlusSign() {
    return (
      <polygon
        fill="#FFEF63"
        points="33.68 19.524 26.948 19.524 26.948 26.364 22.052 26.364 22.052 19.524 15.32 19.524 15.32 14.88 22.052 14.88 22.052 8.004 26.948 8.004 26.948 14.88 33.68 14.88"
      />
    )
  };

  static renderPanel(translate_x, translate_y, hasPlusSign) {
    const translateString = `translate(${translate_x} ${translate_y})`;
    const getPlus = hasPlusSign ? SolarPanelCountComponent.addPlusSign() : null;
    return (
      <g fill="none" transform={translateString}>
        <use fill="#000" filter="url(#a)" xlinkHref="#b" />
        <use fill="url(#c)" xlinkHref="#b" />
        <path stroke="#638387" strokeWidth={0.8} d="M1.575 18h30.45" />
        <path stroke="#7D999D" strokeWidth={0.8} d="M3.851 7.965h26.074" />
        <path stroke="url(#d)" strokeWidth={0.8} d="M13.125 0L9.45 26.4" />
        <path stroke="url(#e)" strokeWidth={0.8} d="M24.16 0l-3.676 26.4" transform="matrix(-1 0 0 1 44.644 0)"/>
        { getPlus }
      </g>
    )
  };

  static createPanels(neededPanels) {
    const
      panel_width = 33,
      panel_height=27,
      start_x = 48,
      start_y = 223,
      horizontal_offset=4,
      vertical_offset=5,
      totalSpace = 8*panel_width + 7*horizontal_offset;

    let panelsArray = [], count = neededPanels, rowCount = neededPanels;

    for (let i = 0; i < 4 && count>0; i++) {
      for (let j = 0; j < 8 && count>0; j++) {
        if (rowCount >= 8) {
          let panel_x = start_x + j*panel_width + j*horizontal_offset;
          let panel_y = start_y + i*panel_height + i*vertical_offset;

          if (i === 3 && j === 7 && rowCount - 8 > 0) {
            // if we have more than 32 panels
            panelsArray.push(
              SolarPanelCountComponent.renderPanel(panel_x, panel_y, true)
            );
          } else {
            panelsArray.push(
              SolarPanelCountComponent.renderPanel(panel_x, panel_y, false)
            );
          }
        } else {
          let extraSpace = panel_width*(count-j) + horizontal_offset*(count-j-1);
          let panel_x = start_x + (totalSpace - extraSpace)/2;
          let panel_y = start_y + i*panel_height + i*vertical_offset;
          panelsArray.push(
            SolarPanelCountComponent.renderPanel(panel_x, panel_y, false)
          );
        }
        count = --count;
      }
      rowCount = rowCount - 8;
    }
    return panelsArray;
  };

  static renderPanels(panelsNumber) {
    return (
      <React.Fragment>
        { SolarPanelCountComponent.createPanels(panelsNumber) }
      </React.Fragment>
    )
  };

  render() {
    const heightSize = this.props.height ? this.props.height : 385;
    const widthSize = this.props.width ? this.props.width : 387;
    const viewBoxSize = `0 0 385 387`;

    return (
      <svg xmlnsXlink="http://www.w3.org/1999/xlink" width={widthSize} height={heightSize} viewBox={viewBoxSize}>
        <defs>
          { SolarPanelCountComponent.renderDefinitions() }
        </defs>
        <g fill="none" fillRule="evenodd">
          <g fillRule="nonzero">
            <use fill="#000" filter="url(#sun_shine_effect)" xlinkHref="#sun_element" />
            <use fill="#FFF" xlinkHref="#sun_element" />
          </g>
          <g fill="#274E53">
            <text fontFamily="Roboto-Regular, Roboto" fontSize={18} opacity={0.8} transform="translate(114 123)">
              <tspan x={79.417} y={70.825} textAnchor="middle">
                { parseInt(this.props.numberOfPanels) === 1 ? `Panel`:`Panels` }
              </tspan>
            </text>
            <text fontFamily="Poppins-SemiBold, Poppins" fontSize={36} fontWeight={500} letterSpacing={-2} transform="translate(114 123)">
              <tspan x={79} y={38.253} textAnchor="middle">
                { this.props.numberOfPanels }
              </tspan>
            </text>
          </g>
          <g fillRule="nonzero">
            { SolarPanelCountComponent.renderPanels(this.props.numberOfPanels) }
            {
              /*
              { SolarPanelsComponent.renderPanel(48,223) }
              { SolarPanelsComponent.renderPanel(85,223) }
              { SolarPanelsComponent.renderPanel(122,223) }
              { SolarPanelsComponent.renderPanel(159,223) }
              { SolarPanelsComponent.renderPanel(196,223) }
              { SolarPanelsComponent.renderPanel(233,223) }
              { SolarPanelsComponent.renderPanel(270,223) }
              { SolarPanelsComponent.renderPanel(307,223) }

              { SolarPanelsComponent.renderPanel(48,255) }
              { SolarPanelsComponent.renderPanel(85,255) }
              { SolarPanelsComponent.renderPanel(122,255) }
              { SolarPanelsComponent.renderPanel(159,255) }
              { SolarPanelsComponent.renderPanel(196,255) }
              { SolarPanelsComponent.renderPanel(233,255) }
              { SolarPanelsComponent.renderPanel(270,255) }
              { SolarPanelsComponent.renderPanel(307,255) }

              { SolarPanelsComponent.renderPanel(48,287) }
              { SolarPanelsComponent.renderPanel(85,287) }
              { SolarPanelsComponent.renderPanel(122,287) }
              { SolarPanelsComponent.renderPanel(159,287) }
              { SolarPanelsComponent.renderPanel(196,287) }
              { SolarPanelsComponent.renderPanel(233,287) }
              { SolarPanelsComponent.renderPanel(270,287) }
              { SolarPanelsComponent.renderPanel(307,287) }

              { SolarPanelsComponent.renderPanel(48,319) }
              { SolarPanelsComponent.renderPanel(85,319) }
              { SolarPanelsComponent.renderPanel(122,319) }
              { SolarPanelsComponent.renderPanel(159,319) }
              { SolarPanelsComponent.renderPanel(196,319) }
              { SolarPanelsComponent.renderPanel(233,319) }
              { SolarPanelsComponent.renderPanel(270,319) }
              { SolarPanelsComponent.renderPanel(307,319) }
              */
            }
          </g>
        </g>
      </svg>
    )
  }
}

export default SolarPanelCountComponent;
