import React, { Component } from 'react';
import 'rc-dialog/assets/index.css';
import DialogWrap from 'rc-dialog';
import './style.scss';

class DialogComponent extends Component {
  state = {
    visible: false,
    width: 600,
    destroyOnClose: false,
    center: true,
    maskClosable: false,
    mousePosition: {},
    useIcon: false,
  };

  onClick = (event) => { this.setState({ visible: true }); };
  onClose = (event) => { this.setState({ visible: false }); };

  render() {
    return (
      <div className="dialog-container" style={{...this.props}}>
        <button
          className='btn-secondary'
          onClick={this.onClick}
          type="button"
        >
          {this.props.buttonText}
        </button>
        <DialogWrap
          maskStyle={{background: '#ffffff', opacity: '0.95'}}
          visible={this.state.visible}
          wrapClassName='dialog-center'
          keyboard={false}
          maskClosable={true}
          onClose={this.onClose}
          style={this.state.width}
          destroyOnClose={this.state.destroyOnClose}
          //closeIcon={getIcon()}
          title={this.props.title}
          footer={
            <button key="close" onClick={this.onClose} type="button">
              {this.props.dismissText}
            </button>
          }>
          <p>{this.props.text}</p>
        </DialogWrap>
      </div>
    );
  }

}

export default DialogComponent;
