import axios from 'axios';
import debounce from 'lodash.debounce';
import store from '../store/index';
import uuid4 from 'uuid4';
import {
  TRIGGER_FETCH_CLIENT_IP_BEGIN,
  TRIGGER_FETCH_CLIENT_IP_FAILURE,
  TRIGGER_FETCH_CLIENT_IP_SUCCESS,
  TRIGGER_FETCH_SESSION_BEGIN,
  TRIGGER_FETCH_SESSION_FAILURE,
  TRIGGER_FETCH_SESSION_SUCCESS,
  UPDATE_APP_CAMPAIGN,
  UPDATE_APP_DOMAIN,
  UPDATE_APP_SESSION,
  UPDATE_CLIENT_IP_ADDRESS
} from '../constants/action_types';
import {
  NAVIGATION_UPDATE_PAGE_ID_ACTION,
  NAVIGATION_UPDATE_PREVIOUS_PAGE_ID_ACTION,
  TRIGGER_FETCH_PAGE_BEGIN_ACTION,
  TRIGGER_FETCH_PAGE_SUCCESS_ACTION,
  TRIGGER_NEXT_PAGE_BEGIN_ACTION,
  TRIGGER_NEXT_PAGE_SUCCESS_ACTION,
  TRIGGER_PREVIOUS_PAGE_BEGIN_ACTION,
  TRIGGER_PREVIOUS_PAGE_SUCCESS_ACTION,
  UPDATE_IS_SAVING_DATA_FLAG_ACTION,
  UPDATE_IS_VERIFYING_DATA_FLAG_ACTION
} from './_navigation_actions';
import {
  UPDATE_SUBMIT_DATA_PAGE_ID_ACTION,
  UPDATE_SUBMIT_DATA_SESSION_ACTION
} from './_submit_actions';
import {
  UPDATE_VALIDATION_STATE_ACTION
} from './_validation_actions';
import {
  WellKnownFields
} from '../../settings';
import {
  history
} from '../../blog/main';


export const TRIGGER_FETCH_CLIENT_IP_BEGIN_ACTION = () => ({
  type: TRIGGER_FETCH_CLIENT_IP_BEGIN
});

export const TRIGGER_FETCH_CLIENT_IP_SUCCESS_ACTION = data => ({
  type: TRIGGER_FETCH_CLIENT_IP_SUCCESS,
  payload: data
});

export const TRIGGER_FETCH_CLIENT_IP_FAILURE_ACTION = error => ({
  type: TRIGGER_FETCH_CLIENT_IP_FAILURE,
  payload: error
});

export const TRIGGER_FETCH_SESSION_BEGIN_ACTION = () => ({
  type: TRIGGER_FETCH_SESSION_BEGIN
});

export const TRIGGER_FETCH_SESSION_SUCCESS_ACTION = data => ({
  type: TRIGGER_FETCH_SESSION_SUCCESS,
  payload: data
});

export const TRIGGER_FETCH_SESSION_FAILURE_ACTION = error => ({
  type: TRIGGER_FETCH_SESSION_FAILURE,
  payload: error
});

export const UPDATE_APP_DOMAIN_ACTION = domain => ({
  type: UPDATE_APP_DOMAIN,
  payload: domain
});

export const UPDATE_APP_CAMPAIGN_ACTION = session => ({
  type: UPDATE_APP_CAMPAIGN,
  payload: session
});

export const UPDATE_APP_SESSION_ACTION = session => ({
  type: UPDATE_APP_SESSION,
  payload: session
});

export const UPDATE_CLIENT_IP_ADDRESS_ACTION = ipAddress => ({
  type: UPDATE_CLIENT_IP_ADDRESS,
  payload: ipAddress
});

export function FETCH_CLIENT_IP_ACTION() {
  return dispatch => {
    const ipAddress = store.getState().api.clientIpAddress;
    const isClientIpFetched = store.getState().api.isClientIpFetched;
    const isClientIpFetching = store.getState().api.isClientIpFetching;

    if (window.clientIPAddress) {
      dispatch(UPDATE_CLIENT_IP_ADDRESS_ACTION(window.clientIPAddress));
    }

    if (ipAddress === '' && !isClientIpFetching && !isClientIpFetched) {
      dispatch(TRIGGER_FETCH_CLIENT_IP_BEGIN_ACTION());
      axios.get(`https://api.ipify.org?format=json`)
      .then(response => {
        dispatch(TRIGGER_FETCH_CLIENT_IP_SUCCESS_ACTION(response.data.ip));
      })
      .catch(error => {
        dispatch(TRIGGER_FETCH_CLIENT_IP_FAILURE_ACTION(error));
      });
    }
  };
}

function callApi(method, data, url) {
  const baseUrl = store.getState().api.baseUrl;

  return axios({
    method: method,
    url: url || baseUrl,
    data: data,
    headers: {
      "Accept": "application/json",
      "Content-Type": "application/json",
      "x-requestid": uuid4()
    }
  });
}

export function FETCH_SESSION_ACTION(pageGuid) {
  return dispatch => {
    const { campaign, clientIpAddress, domain } = store.getState().api;

    if (clientIpAddress === '') {
      dispatch(FETCH_CLIENT_IP_ACTION());
    } else {
      dispatch(TRIGGER_FETCH_SESSION_BEGIN_ACTION());
      callApi('post', {
        data: [{
          "wellKnownFieldName": WellKnownFields.Campaign,
          "value": campaign
        }, {
          "wellKnownFieldName": WellKnownFields.SiteName,
          "value": "Solar"
        }, {
          "wellKnownFieldName": WellKnownFields.IP_Address,
          "value": clientIpAddress
        }],
        domain: domain,
        ipAddress: clientIpAddress
      })
      .then(response => {
        dispatch(TRIGGER_FETCH_SESSION_SUCCESS_ACTION(response.data.SessionID));
        dispatch(UPDATE_SUBMIT_DATA_SESSION_ACTION(response.data.SessionID));

        if (pageGuid) {
          debounce(dispatch(FETCH_PAGE_ACTION(pageGuid)), 500);
        } else {
          debounce(dispatch(FETCH_NEXT_PAGE_ACTION()), 500);
        }
      })
      .catch(error => {
        dispatch(TRIGGER_FETCH_SESSION_FAILURE_ACTION(error));
      });
    }
  };
}

export function FETCH_NEXT_PAGE_ACTION() {
  return dispatch => {
    const { baseUrl, clientIpAddress, session } = store.getState().api;

    dispatch(TRIGGER_NEXT_PAGE_BEGIN_ACTION());
    callApi('get', {
      ipAddress: clientIpAddress
    },`${baseUrl}/${session}/nextIncompletePage`)
    .then(response => {
      dispatch(NAVIGATION_UPDATE_PREVIOUS_PAGE_ID_ACTION(response.data.guid));
      dispatch(NAVIGATION_UPDATE_PAGE_ID_ACTION(response.data.guid));
      dispatch(UPDATE_SUBMIT_DATA_PAGE_ID_ACTION(response.data.guid));
      history.push(`/${response.data.guid}`);
      dispatch(TRIGGER_NEXT_PAGE_SUCCESS_ACTION(response.data));
    });
  };
}

export function FETCH_PAGE_ACTION(pageId) {
  return dispatch => {
    const { baseUrl, clientIpAddress, session } = store.getState().api;

    dispatch(TRIGGER_FETCH_PAGE_BEGIN_ACTION());
    callApi('get', {
      ipAddress: clientIpAddress
    },`${baseUrl}/${session}/pages/${pageId}`)
    .then(response => {
      dispatch(NAVIGATION_UPDATE_PREVIOUS_PAGE_ID_ACTION(pageId));
      dispatch(NAVIGATION_UPDATE_PAGE_ID_ACTION(response.data.guid));
      dispatch(UPDATE_SUBMIT_DATA_PAGE_ID_ACTION(response.data.guid));
      history.push(`/${response.data.guid}`);
      dispatch(TRIGGER_FETCH_PAGE_SUCCESS_ACTION(response.data));
    });
  };
}

export function FETCH_PREVIOUS_PAGE_ACTION(pageId) {
  return dispatch => {
    const { baseUrl, clientIpAddress, session } = store.getState().api;

    dispatch(TRIGGER_PREVIOUS_PAGE_BEGIN_ACTION());
    callApi('get', {
      ipAddress: clientIpAddress
    },`${baseUrl}/${session}/pages/${pageId}/previous`)
    .then(response => {
      dispatch(NAVIGATION_UPDATE_PREVIOUS_PAGE_ID_ACTION(pageId));
      dispatch(NAVIGATION_UPDATE_PAGE_ID_ACTION(response.data.guid));
      dispatch(UPDATE_SUBMIT_DATA_PAGE_ID_ACTION(response.data.guid));
      history.push(`/${response.data.guid}`);
      dispatch(TRIGGER_PREVIOUS_PAGE_SUCCESS_ACTION(response.data));
    });
  };
}

export function SAVE_DATA_ACTION(data, bypassFlag) {
  return dispatch => {
    const { pageId, session } = store.getState().submitInfo;
    const api = store.getState().api.baseUrl;
    const saveUrl = api + `/${session}/pages/${pageId}`;

    dispatch(UPDATE_IS_SAVING_DATA_FLAG_ACTION(true));
    let wrapData = {
      pageID: pageId,
      sessionID: session,
      data: data
    };
    if (bypassFlag != null) {
      wrapData = Object.assign(wrapData, { bypassValidations: bypassFlag });
    }
    axios({
      method: 'post',
      url: saveUrl,
      data: wrapData,
      headers: {
        "Accept": "application/json",
        "Content-Type": "application/json",
        "x-requestid": uuid4()
      }
    }).then(() => {
      dispatch(UPDATE_IS_SAVING_DATA_FLAG_ACTION(false));
      dispatch(FETCH_NEXT_PAGE_ACTION());
    });
  };
}

export function VALIDATE_DATA_ACTION(data) {
  return dispatch => {
    const { pageId, session } = store.getState().submitInfo;
    const api = store.getState().api.baseUrl;
    const validateUrl = api + `/${session}/pages/${pageId}/validate`;

    dispatch(UPDATE_IS_VERIFYING_DATA_FLAG_ACTION(true));
    axios({
      method: 'post',
      url: validateUrl,
      data: {
        pageID: pageId,
        sessionID: session,
        data: data
      },
      headers: {
        "Accept": "application/json",
        "Content-Type": "application/json",
        "x-requestid": uuid4()
      }
    }).then(response => {
      dispatch(UPDATE_VALIDATION_STATE_ACTION(response.data));
      dispatch(UPDATE_IS_VERIFYING_DATA_FLAG_ACTION(false));
    });
  };
}

export function VALIDATE_DATA_AND_TRIGGER_SUBMIT_ACTION(data) {
  return dispatch => {
    const { pageId, session } = store.getState().submitInfo;
    const api = store.getState().api.baseUrl;
    const validateUrl = api + `/${session}/pages/${pageId}/validate`;

    dispatch(UPDATE_IS_VERIFYING_DATA_FLAG_ACTION(true));
    axios({
      method: 'post',
      url: validateUrl,
      data: {
        pageID: pageId,
        sessionID: session,
        data: data
      },
      headers: {
        "Accept": "application/json",
        "Content-Type": "application/json",
        "x-requestid": uuid4()
      }
    }).then(response => {
      dispatch(UPDATE_IS_VERIFYING_DATA_FLAG_ACTION(false));
      dispatch(UPDATE_VALIDATION_STATE_ACTION(response.data));
      if (response.data && response.data.hasErrors === false) {
        dispatch(SAVE_DATA_ACTION(data));
      }
    });
  };
}

