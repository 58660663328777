import React, { Component } from 'react';
import BlogComponent from './js/blog/main/index';
import {
  FETCH_CLIENT_IP_ACTION,
  UPDATE_APP_DOMAIN_ACTION
} from './js/redux/actions';
import connect from "react-redux/es/connect/connect";
import './App.scss';

const mapDispatchToProps = dispatch => {
  return {
    fetchClientIpAddress: () => dispatch(FETCH_CLIENT_IP_ACTION()),
    updateDomain: (domain) => dispatch(UPDATE_APP_DOMAIN_ACTION(domain))
  };
};

class App extends Component {

  componentWillMount() {
    // get domain name
    const siteDomain = window.location.hostname;
    if (siteDomain && siteDomain !== 'localhost') {
      this.props.updateDomain(siteDomain);
    }
  }

  componentDidMount() {
    this.props.fetchClientIpAddress();
  }

  render() {
    return (
      <BlogComponent />
    );
  }
}

export default connect(null, mapDispatchToProps)(App);
