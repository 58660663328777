import React, {Component} from 'react';
import {SAVE_DATA_ACTION} from "../../../redux/actions";
import connect from "react-redux/es/connect/connect";
import './style.scss';

const mapDispatchToProps = dispatch => {
  return {
    saveData: (data) => dispatch(SAVE_DATA_ACTION(data))
  };
};

class ChipItemComponent extends Component {

  handleSubmit = (event) =>{
    event.preventDefault();
    const data = [{
      "fieldId" : this.props.parentFieldId,
      "value": this.props.value
    }];
    this.props.saveData(data);
  };

  render() {
    return (
      <div className="chip-item-container" style={{...this.props}}>
        <div className="chip-item-title">
          <span>{this.props.text}</span>
        </div>
        <div className="chip-item-action">
          <button
            onClick={this.props.isSubmit ? this.handleSubmit : null}
            type="button"
          >
            {this.props.buttonText}
          </button>
        </div>
      </div>
    )
  }
}

export default connect(null, mapDispatchToProps)(ChipItemComponent);
