/*global google*/
import React, {Component} from 'react';
import GoogleMap from 'google-map-react';
import { connect } from 'react-redux';
import { googleConfig } from "../../settings/";
import {UPDATE_MAP_CENTER_ACTION, UPDATE_MAP_SIZE_ACTION} from '../../redux/actions'
import './style.scss';

const mapDispatchToProps = dispatch => {
  return {
    updateMapCenter: center => dispatch(UPDATE_MAP_CENTER_ACTION(center)),
    updateMapSize: size => dispatch(UPDATE_MAP_SIZE_ACTION(size))
  };
};

const mapStateToProps = state => {
  return {
    isDraggable: state.map.isDraggable
  };
};

class CoordMapType {
  constructor(tileSize, darkness) {
    this.tileSize = tileSize;
    this.opacity = darkness ? parseInt(darkness)/10 : 0;
  }

  getTile(coord, zoom, ownerDocument) {
    let div = ownerDocument.createElement('div');
    div.style.backgroundColor = "#1B1B1B";
    div.style.opacity = ""+this.opacity;
    div.style.width = `${this.tileSize.width}px`;
    div.style.height = `${this.tileSize.height}px`;
    return div;
  }
}

class MapStreetComponent extends Component {

  constructor(props) {
    super(props);
    this.state = {
      address: this.props.lat && this.props.lng ? [this.props.lat, this.props.lng] : MapStreetComponent.defaultProps.center,
      center: this.props.lat && this.props.lng ? [this.props.lat, this.props.lng] : MapStreetComponent.defaultProps.center,
      map: null,
      mapSize: {
        width: null,
        height: null
      },
      streetViewAvailable: false
    }
  }

  static defaultProps = {
    center: [40.3870835, -111.7521969],
    zoom: 32
  };

  initMap = (map, maps) => {
    let localThis = this;
    this.dispatchUpdateMapCenter(map);
    this.dispatchUpdateMapSize(map);
    maps.event.addListener(map, 'center_changed', function() {
      localThis.dispatchUpdateMapCenter(map);
    });
    maps.event.addDomListenerOnce(map, 'idle', () => {
      maps.event.addDomListener(window, 'resize', () => {
        localThis.dispatchUpdateMapSize(map);
      });
    });
    this.setState({
      map: map
    }, this.calculateHeading);
  };

  createMapOptions() {
    return {
      draggable: this.state.streetViewAvailable,
      fullscreenControl: false,
      mapTypeControl: false,
      mapTypeId: 'satellite',
      tilt: this.props.showPerspective ? 45 : 0,
      overviewMapControl: false,
      scrollwheel: false
    };
  }

  dispatchUpdateMapCenter(map) {
    if (map) {
      let {lat, lng} = map.getCenter();
      this.props.updateMapCenter([lat(), lng()]);
    }
  }

  dispatchUpdateMapSize(map) {
    if (map) {
      this.props.updateMapSize({
        height: map.getDiv().offsetHeight,
        width: map.getDiv().offsetWidth
      });
    }
  }

  calculateHeading() {
    const localThis = this;
    const streetViewMaxDistance = 50;
    const point = new google.maps.LatLng(this.state.center[0], this.state.center[1]);
    const streetViewService = new google.maps.StreetViewService();
    // We get the map's default panorama and set up some defaults.
    // Note that we don't yet set it visible.

    streetViewService.getPanoramaByLocation(point, streetViewMaxDistance, function (streetViewPanoramaData, status) {

      if (status === google.maps.StreetViewStatus.OK) {
        const newPoint = streetViewPanoramaData.location.latLng;

        localThis.state.map.overlayMapTypes.insertAt(0, new CoordMapType(new google.maps.Size(256, 256), 9));

        localThis.setState({ streetViewAvailable: true });

        localThis.state.map.streetView = new google.maps.StreetViewPanorama(
          document.getElementById('street-view-container'), {
            position: point,
            pov: {
              heading: google.maps.geometry.spherical.computeHeading(newPoint, point),
              pitch: 0
            }
          });
      } else {
        // no street view available in this range, or some error occurred
        localThis.setState({ streetViewAvailable: false });
      }
    });
  }

  render() {
    return (
      <React.Fragment>
        <GoogleMap
          bootstrapURLKeys={{key: googleConfig.apiKey}}
          center={this.state.center}
          onGoogleApiLoaded={({map, maps}) => this.initMap(map, maps)}
          yesIWantToUseGoogleMapApiInternals
          resetBoundsOnResize={true}
          options={this.createMapOptions()}
          zoom={this.props.zoom}
          // instead of css hover (which sometimes is bad for map markers) (bad means inability to hover on markers placed under other markers)
          // you can use internal GoogleMap component hover algorithm hover algorithm explained at x_distance_hover example
          hoverDistance={ 40 / 2}
        >
        </GoogleMap>
        {
          this.state.streetViewAvailable ?
            <div className="street-view-container">
              <div id="street-view-container" />
            </div>
          :
            null
        }
      </React.Fragment>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(MapStreetComponent);
