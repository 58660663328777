import React, {Component} from 'react';
import { SAVE_DATA_ACTION } from "../../../redux/actions";
import connect from "react-redux/es/connect/connect";
import './style.scss';

const mapDispatchToProps = dispatch => {
  return {
    saveData: (data) => dispatch(SAVE_DATA_ACTION(data))
  };
};

class OptionItem extends Component {

  handleSubmit = (event) =>{
    event.preventDefault();
    const data = [{
      "fieldId" : this.props.parentFieldId,
      "value": this.props.value
    }];
    this.props.saveData(data);
  };

  render() {
    /*
    const svgString = encodeURIComponent(this.props.imageContent);
    const dataUri = `url("data:image/svg+xml;charset=utf-8,${svgString}")`;
    */
    const propPath = this.props.imageUrl;
    const isFullPath = propPath ? propPath.startsWith("http") : false;
    const imgPath = isFullPath ? propPath : `../assets/${propPath}`;

    return (
      <div className="image-option-item">
        <button
          onClick={this.handleSubmit}
          style={{...this.props}}
          type="button"
        >
          <img alt={this.props.alt} src={imgPath} style={{height: '100%', width: '100%', borderRadius: '70px'}} />
          {
          /*
          <div style={{backgroundImage: dataUri, height: '100%', width: '100%'}} />
          */
          }
        </button>
      </div>
    )
  }
}

export default connect(null, mapDispatchToProps)(OptionItem);
