import React from "react";
import "./style.scss";

const HelperText = ({ text = "", className = "helperText", imgSrc = "" }) => {
  return (
    <div className={className}>
      {imgSrc !== "" ? <img alt="#" src={imgSrc} /> : ""}
      <h3>{text}</h3>
    </div>
  )
};

export default HelperText
