import React, { Component } from 'react';
import {
  APPEND_SUBMIT_DATA_ACTION,
  REMOVE_SUBMIT_DATA_BY_ID_ACTION
} from "../../redux/actions";
import connect from "react-redux/es/connect/connect";

const mapDispatchToProps = dispatch => {
  return {
    appendSubmitData: data => dispatch(APPEND_SUBMIT_DATA_ACTION(data)),
    removeSubmitData: id => dispatch(REMOVE_SUBMIT_DATA_BY_ID_ACTION(id))
  };
};

class PageViewedComponent extends Component {
  componentDidMount() {
    const data = {
      fieldId: this.props.fieldID,
      value: true
    };
    this.props.appendSubmitData(data);
  }

  componentWillUnmount() {
    this.props.removeSubmitData(this.props.fieldID);
  }

  render() {
    return (
      <div style={{...this.props}}>
        {this.props.children}
      </div>
    )
  }

}

export default connect(null, mapDispatchToProps)(PageViewedComponent);
