import React, { Component } from 'react';
import EngineContainerComponent from '../engine/container';
import DynamicArticlePage from "../article/dynamic/page";
import MainBlogComponent from "./main";
import {Route, Router, Switch} from "react-router";
import RouteNotMatched from "../../containers/404";
import {createBrowserHistory} from "history";
import uuid4 from 'uuid4';

import article1 from '../text/article1';
import article2 from '../text/article2';
import article3 from '../text/article3';
import article4 from '../text/article4';
import article5 from '../text/article5';

export const history = createBrowserHistory();
window.appHistory = history;

class BlogComponent extends Component {

  render() {
    return (
      <Router history={history}>
        <Switch>
          <Route path='/:guid?'
                 component={EngineContainerComponent}
          />
          <Route
            path='/solar-energy-advantages-and-disadvantages'
            render={ () => <DynamicArticlePage key={uuid4()} article={article5} /> }
          />
          <Route
            path='/2018-annual-solar-review'
            render={ () => <DynamicArticlePage key={uuid4()} article={article1} /> }
          />
          <Route
            path='/2018-state-solar-policies-updates-you-should-know'
            render={ () => <DynamicArticlePage key={uuid4()} article={article2} /> }
          />
          <Route
            path='/installation-cost-of-solar-panels-in-2018'
            render={ () => <DynamicArticlePage key={uuid4()} article={article3} /> }
          />
          <Route
            path='/how-to-find-the-best-solar-providers-near-me'
            render={ () => <DynamicArticlePage key={uuid4()} article={article4} /> }
          />
          <Route component={RouteNotMatched} />
        </Switch>
      </Router>
    );
  }
}

export default BlogComponent;
