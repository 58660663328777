import React, { Component } from 'react';
import Slider from 'rc-slider';
import {
  APPEND_SUBMIT_DATA_ACTION,
  REMOVE_SUBMIT_DATA_BY_ID_ACTION,
  RESET_VALIDATION_STATE_ACTION,
  UPDATE_SUBMIT_DATA_VALUE_BY_ID_ACTION
} from "../../redux/actions";
import connect from "react-redux/es/connect/connect";
import 'rc-slider/assets/index.css';
import './style.scss';

const mapDispatchToProps = dispatch => {
  return {
    appendSubmitData: data => dispatch(APPEND_SUBMIT_DATA_ACTION(data)),
    updateSubmitData: obj => dispatch(UPDATE_SUBMIT_DATA_VALUE_BY_ID_ACTION(obj)),
    removeSubmitData: id => dispatch(REMOVE_SUBMIT_DATA_BY_ID_ACTION(id)),
    resetValidationState: () => dispatch(RESET_VALIDATION_STATE_ACTION())
  };
};

class SliderComponent extends Component {

  constructor(props) {
    super(props);
    this.state = {
      max: this.props.maxValue || 100,
      min: this.props.minValue || 0,
      sliderValue: parseInt(this.props.value) || parseInt(this.props.minValue) || 0
    };
    this.handleChange = this.handleChange.bind(this);
  }

  componentDidMount() {
    const obj = {
      fieldId: this.props.fieldID,
      value: this.state.sliderValue
    };
    this.props.appendSubmitData(obj);
  }

  componentWillUnmount() {
    this.props.removeSubmitData(this.props.fieldID);
    this.props.resetValidationState();
  }

  handleSubmitData() {
    const obj = {
      fieldId: this.props.fieldID,
      value: this.state.sliderValue
    };
    this.props.updateSubmitData(obj);
  }

  handleChange(value) {
    this.setState({
      sliderValue: value
    }, this.handleSubmitData);
  }

  render() {
    const { max, min, sliderValue } = this.state;
    let marks = {};
    if (this.props.minLabel && this.props.maxLabel) {
      marks[min] = this.props.minLabel;
      marks[max] = this.props.maxLabel;
    }
    return (
      <React.Fragment>
        <div className="slider-container">
          <h3>${sliderValue}</h3>
          <Slider
            min={min}
            //marks={marks}
            max={max}
            onChange={this.handleChange}
            value={typeof sliderValue === 'number' ? sliderValue : 0}
          />
        </div>
      </React.Fragment>
    );
  }

}

export default connect(null, mapDispatchToProps)(SliderComponent);
