import React from 'react';
import SvgLogoComponent from "./logo";
import {ReactComponent as BackIcon} from './_back.svg';
import './style.scss';


class PageTitleLogoComponent extends React.Component {
  moveTo = (ev) => {
    ev.preventDefault();
    this.props.fetchPreviousPage(this.props.pageId);
  };

  render() {
    return (
      <div className="page-title-logo-container" style={{...this.props}}>
        <SvgLogoComponent
          className="page-title-logo"
          fill={this.props.fillColor || '#195054'}
          width={this.props.width || 210}
          height={this.props.height || 30}
        />
        {
          this.props.hasBackButton ?
            <div className="page-back-btn-container">
              <button onClick={this.moveTo} type="button">
                <BackIcon className='svg-icon'/>
              </button>
            </div>
          :
            null
        }
      </div>
    )
  }
}

export default PageTitleLogoComponent;
