import React, { Component } from 'react';
import { ReactComponent as SearchIcon } from './_search.svg';
import PlacesAutocomplete from 'react-places-autocomplete';
import {
  APPEND_SUBMIT_DATA_ACTION,
  REMOVE_SUBMIT_DATA_BY_ID_ACTION,
  RESET_VALIDATION_STATE_ACTION,
  UPDATE_BYPASS_ATTEMPTS_COUNT_SUBMIT_FLAG_ACTION,
  UPDATE_MAP_ADDRESS_ACTION,
  UPDATE_SUBMIT_ATTEMPTS_ACTION,
  UPDATE_SUBMIT_DATA_VALUE_BY_ID_ACTION, VALIDATE_DATA_AND_TRIGGER_SUBMIT_ACTION
} from "../../../redux/actions";
import connect from "react-redux/es/connect/connect";
import emitter from '../../../common/eventemitter';
import './style.scss';

const mapDispatchToProps = dispatch => {
  return {
    appendSubmitData: data => dispatch(APPEND_SUBMIT_DATA_ACTION(data)),
    updateSubmitData: obj => dispatch(UPDATE_SUBMIT_DATA_VALUE_BY_ID_ACTION(obj)),
    removeSubmitData: id => dispatch(REMOVE_SUBMIT_DATA_BY_ID_ACTION(id)),
    updateBypassValue: number => dispatch(UPDATE_BYPASS_ATTEMPTS_COUNT_SUBMIT_FLAG_ACTION(number)),
    updateSubmitAttempts: number => dispatch(UPDATE_SUBMIT_ATTEMPTS_ACTION(number)),
    resetValidationState: () => dispatch(RESET_VALIDATION_STATE_ACTION()),
    updateMapAddress: address => dispatch(UPDATE_MAP_ADDRESS_ACTION(address)),
    validateDataAndSave: (data) => dispatch(VALIDATE_DATA_AND_TRIGGER_SUBMIT_ACTION(data))
  };
};

const mapStateToProps = state => {
  return {
    data: state.submitInfo.submitData.data,
    submitAttempts: state.submitInfo.submitAttempts
  };
};

class AddressTextBoxComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      address: this.props.value ? this.props.value : '',
      isError: false,
      errorMessage: ''
    };
    this.emitterSubscription = null;
  }

  componentDidMount() {
    const obj = {
      fieldId: this.props.fieldID,
      value: this.state.address
    };
    this.emitterSubscription = emitter.addListener('validate-local', () => {this.handleLocalValidation()});
    this.props.appendSubmitData(obj);
  }

  componentWillUnmount() {
    this.props.removeSubmitData(this.props.fieldID);
    this.props.resetValidationState();
    this.props.updateSubmitAttempts(0);
    this.emitterSubscription.remove();
  }

  handleLocalValidation(callback) {
    if (this.state.address === '') {
      this.setState({
        isError: true,
        errorMessage: 'You need to enter an address in order to continue.'
      });
    } else if (this.props.minLength && this.state.address.length < this.props.minLength) {
      this.setState({
        isError: true,
        errorMessage: `The address value should have a minimum of ${this.props.minLength} characters.`
      });
    } else if (this.props.maxLength && this.state.address.length > this.props.maxLength) {
      this.setState({
        isError: true,
        errorMessage: `The address value should have a maximum of ${this.props.maxLength} characters.`
      });
    } else {
      this.setState({
        isError: false,
        errorMessage: ''
      });
    }
    if (arguments.length>0) {
      callback();
    }
  }

  errorMessageFactory() {
    let { errors, hasErrors } = this.props;
    if (this.state.isError) {
      return (
        <div className="searchbox-error-message">
          {this.state.errorMessage}
        </div>
      )
    }
    if (hasErrors) {
      if (errors) {
        const firstError = errors.find(item => {
          return item.fieldID === this.props.fieldID && item.errorDescription != null && '' !== item.errorDescription;
        });
        if (firstError) {
          return (
            <div className="searchbox-error-message">
              {firstError.errorDescription}
            </div>
          )
        }
      } else {
        return (
          <div className="searchbox-error-message">
            Unknown error, missing error description.
          </div>
        )
      }
    }
    return (
      <span>&nbsp;</span>
    )
  }

  handleSubmit = () =>{
    this.handleLocalValidation(() => {
      if (!this.state.isError) {
        this.props.updateSubmitAttempts(this.props.submitAttempts+1);

        const { data } = this.props;
        this.props.validateDataAndSave(data);
      }
    });
  };

  handleChangeData() {
    const obj = {
      fieldId: this.props.fieldID,
      value: this.state.address
    };
    this.props.updateMapAddress(this.state.address);
    this.props.updateSubmitData(obj);
  }

  handleChange = address => {
    this.setState({ address }, this.handleChangeData);
  };

  handleSelect = address => {
    this.setState({ address }, () => {
      this.handleChangeData();
      this.handleSubmit();
    });
  };

  render() {
    const placeholderValue = this.props.placeholder || 'Search Address ...';
    return (
      <div className="address-textbox-container" style={{...this.props}}>
        <PlacesAutocomplete
          value={this.state.address}
          onChange={this.handleChange}
          onSelect={this.handleSelect}
          //onPlaceSelected={(place) => { console.log(place);}}
          types={['address']}
          componentRestrictions={{country: 'us'}}
          shouldFetchSuggestions={this.state.address.length > 2}
        >
          {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
            <div className="search-container">
              <div className="search-box">
                <input
                  autoFocus={true}
                  {...getInputProps({
                    placeholder: placeholderValue,
                    className: 'search-input',
                  })}
                />
                {
                  this.props.shouldShowSearchIcon ? <button
                    className="search-icon"
                    onClick={this.handleSubmit}
                    tabIndex="0"
                    type="button"
                  >
                    <SearchIcon />
                  </button> : null
                }
                <div className="search-input-autocomplete">
                  {loading && <div>Loading...</div>}
                  {suggestions.map(suggestion => {
                    const className = suggestion.active
                      ? 'autocomplete-item-active'
                      : 'autocomplete-item';
                    // inline style for demonstration purpose
                    const style = suggestion.active
                      ? { backgroundColor: '#fafafa', cursor: 'pointer' }
                      : { backgroundColor: '#ffffff', cursor: 'pointer' };
                    return (
                      <div
                        {...getSuggestionItemProps(suggestion, {
                          className,
                          style,
                        })}
                      >
                        <span>{suggestion.description}</span>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          )}
        </PlacesAutocomplete>
        { this.errorMessageFactory() }
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(AddressTextBoxComponent);
