import React from 'react';

const RowComponent = (props) => {
  const style = {
    display: 'flex',
    flexDirection: 'row',
    overflow: 'auto'
  };
  return (
    <div style={{...style, ...props}}>
      {props.children}
    </div>
  );
};

export default RowComponent;
