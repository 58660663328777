import React from 'react';
import './style.scss';

const ArticleTitleComponent = (props) => (
  <div className="article-title" style={{...props}}>
    <h1>{props.title}</h1>
  </div>
);

export default ArticleTitleComponent;
