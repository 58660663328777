// Api
export const TRIGGER_FETCH_CLIENT_IP_BEGIN = "TRIGGER_FETCH_CLIENT_IP_BEGIN";
export const TRIGGER_FETCH_CLIENT_IP_SUCCESS = "TRIGGER_FETCH_CLIENT_IP_SUCCESS";
export const TRIGGER_FETCH_CLIENT_IP_FAILURE = "TRIGGER_FETCH_CLIENT_IP_FAILURE";
export const TRIGGER_FETCH_SESSION_BEGIN = "TRIGGER_FETCH_SESSION_BEGIN";
export const TRIGGER_FETCH_SESSION_SUCCESS = "TRIGGER_FETCH_SESSION_SUCCESS";
export const TRIGGER_FETCH_SESSION_FAILURE = "TRIGGER_FETCH_SESSION_FAILURE";
export const UPDATE_APP_CAMPAIGN = "UPDATE_APP_CAMPAIGN";
export const UPDATE_APP_DOMAIN = "UPDATE_APP_DOMAIN";
export const UPDATE_APP_SESSION = "UPDATE_APP_SESSION";
export const UPDATE_CLIENT_IP_ADDRESS = "UPDATE_CLIENT_IP_ADDRESS";

// AzimuthComponent
export const UPDATE_AZIMUTH_COMPONENT_ACTIVE_FLAG = "UPDATE_AZIMUTH_COMPONENT_ACTIVE_FLAG";
export const UPDATE_AZIMUTH_COMPONENT_FIELD_ID = "UPDATE_AZIMUTH_COMPONENT_FIELD_ID";
export const UPDATE_AZIMUTH_VALUE = "UPDATE_AZIMUTH_VALUE";

// LocationComponent
export const UPDATE_LOCATION_COMPONENT_ACTIVE_FLAG = "UPDATE_LOCATION_COMPONENT_ACTIVE_FLAG";
export const UPDATE_LOCATION_COMPONENT_FIELD_ID  = "UPDATE_LOCATION_COMPONENT_FIELD_ID";

// Map
export const UPDATE_MAP_ADDRESS = "UPDATE_MAP_ADDRESS";
export const UPDATE_MAP_CENTER = "UPDATE_MAP_CENTER";
export const UPDATE_MAP_DRAG_ABILITY = "UPDATE_MAP_DRAG_ABILITY";
export const UPDATE_MAP_SIZE = "UPDATE_MAP_SIZE";
export const UPDATE_MAP_SIZE_FLAG = "UPDATE_MAP_SIZE_FLAG";

// Submit Data Actions
export const APPEND_SUBMIT_DATA = "APPEND_SUBMIT_DATA";
export const REMOVE_SUBMIT_DATA_BY_ID = "REMOVE_SUBMIT_DATA_BY_ID";
export const UPDATE_SUBMIT_DATA = "UPDATE_SUBMIT_DATA";
export const UPDATE_SUBMIT_DATA_VALUE_BY_ID = "UPDATE_SUBMIT_DATA_VALUE_BY_ID";
export const UPDATE_SUBMIT_DATA_PAGE_ID = "UPDATE_SUBMIT_DATA_PAGE_ID";
export const UPDATE_SUBMIT_DATA_SESSION = "UPDATE_SUBMIT_DATA_SESSION";
export const UPDATE_BYPASS_BOOL_SUBMIT_FLAG = "UPDATE_BYPASS_BOOL_SUBMIT_FLAG";
export const UPDATE_BYPASS_ATTEMPTS_COUNT_SUBMIT_FLAG = "UPDATE_BYPASS_ATTEMPTS_COUNT_SUBMIT_FLAG";
export const UPDATE_SUBMIT_ATTEMPTS = "UPDATE_SUBMIT_ATTEMPTS";
export const UPDATE_TRUSTED_FORM_CERTIFICATE = "UPDATE_TRUSTED_FORM_CERTIFICATE";
export const UPDATE_JORNAYA_TOKEN = "UPDATE_JORNAYA_TOKEN";

// Validation Actions
export const APPEND_VALIDATION_ITEM = "APPEND_VALIDATION_ITEM";
export const UPDATE_VALIDATION_HAS_ERRORS_FLAG = "UPDATE_VALIDATION_HAS_ERRORS_FLAG";
export const UPDATE_VALIDATION_ITEMS = "UPDATE_SUBMIT_DATA";
export const UPDATE_VALIDATION_PAGE_ID = "UPDATE_VALIDATION_PAGE_ID";
export const RESET_VALIDATION_STATE = "RESET_VALIDATION_STATE";
export const UPDATE_VALIDATION_STATE = "UPDATE_VALIDATION_STATE";
