import React from 'react';
import {Link} from "react-router-dom";
import './style.scss';


const RouteNotMatched = ({location}) => {
  return (
    <div className="route_404">
      <h3 className="title_404">404</h3>
      <span className="line_1_404">Oops, sorry we can't find that page!</span>
      <span className="line_2_404">No match for route: <code>{location.pathname}</code></span>
      <br/>
      <span className="line_3_404">Either something went wrong or the page doesn't exist anymore.</span>
      <br/>
      <Link to="/">Home Page</Link>
    </div>
  )
};

export default RouteNotMatched;
